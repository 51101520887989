import React, { useState, useEffect } from 'react';
import { StyleCard, SiteEntry, SiteLogo } from './style';
import usePassword from '../../../hooks/usePassword';
import { LuChevronRight } from 'react-icons/lu';
import theme from '../../../../theme';
import { Card, Flex, Box, Text } from '../../../../storyBook';
import { Hr, CenterText } from '../../../_common/style';
import ShowPasswordModal from '../ShowPasswordModal';
import Loader from '../../../_common/Loader';
import { useSearch } from '../../../hooks/useSearch';

const PasswordList = () => {
  const { passwords, loading } = usePassword();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPasswordId, setSelectedPasswordId] = useState<string | null>(
    null
  );
  const { searchTerm, setActiveSearchArea } = useSearch();

  useEffect(() => {
    if (passwords.length > 1) {
      setActiveSearchArea('password');
    } else {
      setActiveSearchArea('');
    }
  }, [passwords.length, setActiveSearchArea]);

  if (loading) {
    return <Loader isLoading={true} />;
  }
  if (!passwords.length) {
    return (
      <CenterText mt={3}>
        <Text bold>No passwords </Text>
      </CenterText>
    );
  }

  const passwordSearch = passwords.filter((password) => {
    const websiteMatch = (password.website ?? '')
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return websiteMatch;
  });

  const handlePasswordClick = (passwordId: string) => {
    setIsModalOpen(true);
    setSelectedPasswordId(passwordId ?? '');
  };

  if (passwordSearch.length === 0) {
    return (
      <Card background={theme.colors.primary} height={150} border>
        <CenterText p={3} mt={2}>
          No passwords found for the given search criteria.
        </CenterText>
      </Card>
    );
  }

  return (
    <StyleCard>
      {passwordSearch.map((password, index) => (
        <React.Fragment key={password._id}>
          <Box onClick={() => handlePasswordClick(password._id ?? '')}>
            <SiteEntry
              mb={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex alignItems="center">
                <SiteLogo src={password.logoUrl} alt="logo" />
                <Text pl={2}>{password.website}</Text>
              </Flex>
              <LuChevronRight size={20} color={theme.colors.medium} />
            </SiteEntry>
          </Box>
          <Box py={2}>{index < passwords.length - 1 && <Hr />}</Box>
        </React.Fragment>
      ))}
      {isModalOpen && (
        <ShowPasswordModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          passwordId={selectedPasswordId}
        />
      )}
    </StyleCard>
  );
};

export default PasswordList;

import { useState } from 'react';
import apiCaller from '../../Utils/apiCaller';
import { handleError } from '../../Utils/helper';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';

type SignupProps = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
};

type SignupResponse = {
  status: string;
};

const useSignup = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<SignupResponse | null>(null);
  const apiBaseUrl = getApiBaseUrl();

  const signup = async (credentials: SignupProps) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<SignupResponse>({
        endpoint: `${apiBaseUrl}/auth/register`,
        method: 'POST',
        body: credentials,
      });
      setData(response);
    } catch (err) {
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  return {
    signup,
    loading,
    error,
    data,
  };
};

export default useSignup;

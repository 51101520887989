import { useContext } from 'react';
import { PasswordContext } from '../../Context/PasswordContext';
import { PasswordContextType } from '../../Context/PasswordContext/types';

const usePassword = (): PasswordContextType => {
  const context = useContext(PasswordContext);

  if (context === null) {
    throw new Error('usePassword must be used within an PasswordProvider');
  }

  return {
    ...context,
  };
};

export default usePassword;

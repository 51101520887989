import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import { AuthContextType } from '../../Context/AuthContext/types';

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return {
    ...context,
    setIsAuthenticated: context.setIsAuthenticated,
  };
};

export default useAuth;

import React, { createContext, useEffect, useState, ReactNode } from 'react';
import io, { Socket } from 'socket.io-client';
import { useNotifications } from '../NotificationsContext';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';

export const WebSocketContext = createContext<Socket | null>(null);
const apiBaseUrl = getApiBaseUrl();

export const WebSocketProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { addNotification } = useNotifications();
  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');

  useEffect(() => {
    if (userDetails._id) {
      const SOCKET_SERVER_URL = apiBaseUrl || '';

      const newSocket = io(SOCKET_SERVER_URL, {
        reconnection: true,
        reconnectionAttempts: 2,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        transports: ['websocket', 'polling'],
        withCredentials: true,
        timeout: 20000,
      });

      newSocket.on('error', (error) => {
        console.error('WebSocket error:', error);
      });

      newSocket.on('connect', () => {
        setSocket(newSocket);
        console.log('Socket connected:', newSocket.id);
        newSocket.emit('register', { userId: userDetails._id });
      });

      newSocket.on('notification', (notification) => {
        addNotification(notification);
      });

      newSocket.on('disconnect', (reason) => {
        console.log(`Socket disconnected due to: ${reason}`);
        if (reason === 'io server disconnect') {
          // The server can potentially force a disconnect
          newSocket.connect();
        }
      });

      newSocket.on('reconnect_failed', () => {
        console.error('Socket failed to reconnect after maximum attempts.');
        setSocket(null); //  update the state to reflect that the socket is no longer active
      });

      return () => {
        newSocket.close();
      };
    }
    return () => {}; // Return a noop function if the effect does not establish a socket
  }, [userDetails._id]);

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};

import styled from 'styled-components';
import theme from '../../theme';
import { rem } from 'polished';

export const SwitchContainer = styled.div<{ isOn: boolean }>`
  width: 60px;
  height: 30px;
  border-radius: ${rem(15)};
  background-color: ${(props) =>
    props.isOn ? theme.colors.success : theme.colors.primaryRed};
  display: flex;
  justify-content: ${(props) => (props.isOn ? 'flex-end' : 'flex-start')};
  padding: 2px;
  cursor: pointer;
  color: white;
  position: relative;
`;

export const Toggle = styled.div<{ isOn: boolean }>`
  width: 26px;
  height: 26px;
  font-size: ${rem(12)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.veryDark};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  position: absolute;
  left: ${(props) => (props.isOn ? '${rem(32)}' : '${rem(2)}')};
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: ${rem(14)};
  line-height: 1.43;
  letter-spacing: ${rem(0.25)};
  font-weight: 600;
`;

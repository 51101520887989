import { Switch, Route } from 'react-router-dom';
import SignIn from '../Login/SignIn';
import SignUp from '../Login/SignUp';
import GoogleAuthHandler from '../Login/GoogleAuthHandler';
import ResetPassword from '../Login/ResetPassword';
import ResetMasterPassword from '../pages/PasspordSaver/ResetMasterPassword';
import Dashboard from '../Dashboard';
import Profile from '../Header/Profile';
import Details from '../Header/Profile/Details';
import Timeline from '../Timeline';
import SnapNotes from '../SnapNotes';
import Notifications from '../Notifications';
import TimelineForm from '../pages/TimelineForm';
import SnapNoteForm from '../pages/SnapNoteForm';
import PasswordSaver from '../pages/PasspordSaver';
import PasswordCard from '../pages/PasspordSaver/PasswordCard';
import SetMasterPassword from '../pages/PasspordSaver/SetMasterPassword';
import { PrivacyPolicy } from '../../privacyPolicy/privacyPolicy';
import AddFeedback from '../Feedback';
import Documents from '../Documents';

const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path="/" component={SignIn} exact />
        <Route path="/login" component={SignIn} exact />
        <Route
          path="/google-auth-handler"
          component={GoogleAuthHandler}
          exact
        />
        <Route path="/signup" component={SignUp} exact />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/reset-master-password" component={ResetMasterPassword} />
        <Route path="/profile/details" component={Details} />
        <Route path="/profile/privacy-policy" component={PrivacyPolicy} />
        <Route path="/profile/feedback" component={AddFeedback} />
        <Route path="/profile/documents" component={Documents} />
        <Route path="/profile" component={Profile} />
        <Route path="/timeline/edit/:noteId" component={TimelineForm} />
        <Route path="/snapnotes/edit/:noteId" component={SnapNoteForm} />
        <Route path="/passwords/:passwordId" component={PasswordCard} />
        <Route path="/snapnotes" component={SnapNotes} />
        <Route path="/timeline" component={Timeline} />
        <Route path="/password" component={PasswordSaver} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/set-master-password" component={SetMasterPassword} />
        <Route path="/dashboard/add-new-timeline" component={TimelineForm} />
        <Route path="/dashboard/add-new-snapnote" component={SnapNoteForm} />
        <Route path="/dashboard" component={Dashboard} />
      </Switch>
    </>
  );
};

export default Routes;

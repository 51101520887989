import { useContext } from 'react';
import { SearchContext, SearchContextType } from '../../Context/SearchContext';

export const useSearch = (): SearchContextType => {
  const context = useContext(SearchContext);

  if (context === null) {
    throw new Error('useSearch must be used within a SearchProvider');
  }

  return {
    ...context,
  };
};

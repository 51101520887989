// Assuming the types for better clarity and handling
interface ResizeCallback {
  (blob: Blob | null): void; // Callback that either gets a Blob or null if something failed
}

export const resizeImage = (
  file: File,
  maxWidth: number = 800,
  maxHeight: number = 600,
  quality: number = 0.7,
  callback: ResizeCallback
): void => {
  const reader = new FileReader();
  reader.onload = function (event) {
    const img = new Image();
    img.onload = function () {
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      // Check if context is null
      if (ctx === null) {
        callback(null); // Notify callback with null indicating failure
        return;
      }

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          if (blob && blob.size > 5 * 1024 * 1024) {
            resizeImage(file, maxWidth, maxHeight, quality - 0.1, callback);
          } else {
            callback(blob); // Return the blob via callback
          }
        },
        'image/jpeg',
        quality
      );
    };
    if (event.target?.result) {
      img.src = event.target.result.toString(); // Ensuring result is treated as a string
    }
  };
  reader.readAsDataURL(file);
};

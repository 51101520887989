import typography from './typography';
import spacing from './spacing';
import colors from './colors';
import media from './media';
import { breakpointNames, breakpoints } from './media/breakpoints';

const theme = {
  typography,
  spacing,
  colors,
  media,
  breakpoints,
  breakpointNames,
};

export default theme;

import React, { useState, useEffect } from 'react';
import { registerPushSubscription } from '../src/component/Utils/pushNotificationUtils';
import useAuth from '../src/component/hooks/useAuth';
import { Box, Button } from './storyBook';

function NotificationPermissionManager() {
  const { isAuthenticated } = useAuth();
  const [permission, setPermission] = useState(Notification.permission);

  useEffect(() => {
    // Update permission state when the component mounts
    setPermission(Notification.permission);
  }, []);

  const handleEnableNotifications = async () => {
    console.log('Requesting notification permission...');

    if (!('Notification' in window)) {
      console.error('This browser does not support notifications.');
      return;
    }

    const permissionResult = await Notification.requestPermission();
    setPermission(permissionResult);

    if (permissionResult === 'granted') {
      if ('serviceWorker' in navigator) {
        try {
          const registration = await navigator.serviceWorker.register(
            '/service-worker.js'
          );

          // Call registerPushSubscription after service worker is ready
          await registerPushSubscription(registration, isAuthenticated);
        } catch (error) {
          console.error('Service Worker registration failed:', error);
        }
      } else {
        console.error('Service Workers are not supported in this browser.');
      }
    } else if (permissionResult === 'denied') {
      console.warn('Notification permission was denied.');
    } else {
      console.warn('Notification permission was dismissed or closed.');
    }
  };

  // Conditionally render the button based on permission state
  if (permission === 'granted') {
    return null; // Don't render anything if permission is granted
  }

  return (
    <Box width="350px" margin="auto">
      <Button bold styleType="primary" onClick={handleEnableNotifications}>
        Enable Notifications
      </Button>
    </Box>
  );
}

export default NotificationPermissionManager;

import { useEffect } from 'react';
import { LayoutBase } from '../../storyBook';
import Navigition from '../Navigation';
import Header from '../Header';
import { useLocation, useHistory } from 'react-router-dom';
import { ToastContainer } from '../../storyBook/TostNotification';
import useAuth from '../hooks/useAuth';
import Loader from '../_common/Loader';

interface LayoutProps {
  children: React.ReactNode;
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const { loading } = useAuth();
  const history = useHistory();
  const excludedRoutes = ['/', '/login', '/signup', '/reset-password'];

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  useEffect(() => {
    const localAuth = localStorage.getItem('isAuthenticated') === 'true';

    if (!loading) {
      if (localAuth) {
        if (location.pathname === '/' || location.pathname === '/login') {
          history.push('/dashboard');
        }
      } else if (!excludedRoutes.includes(location.pathname)) {
        history.push('/login');
      }
    }
  }, [excludedRoutes, loading, location, history]);

  if (excludedRoutes.includes(location.pathname)) {
    return (
      <>
        <ToastContainer />
        {children}
      </>
    );
  }

  // Render the main layout for authenticated users
  return (
    <>
      <ToastContainer />
      <Header />
      <LayoutBase navigition={<Navigition />}>{children}</LayoutBase>
    </>
  );
};

export default Layout;

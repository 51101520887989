import { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useTimeline from '../../hooks/useTimeline';
import { ErrorMessage } from '../../_common/style';
import {
  Flex,
  Back,
  Select,
  Text,
  TextArea,
  Box,
  Button,
  Switch,
  useIsMedia,
} from '../../../storyBook';
import { Form } from '../../_common/style';
import theme from '../../../theme';
import { validateForm, validateField } from '../../Utils/validation';
import { TimelineData } from '../../Context/TimelineContext/types';
import DateAndTimePicker from '../../../storyBook/DateAndTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useHistory } from 'react-router-dom';
import { parseTime } from '../../Utils/helper';
import { ImSpinner8 } from 'react-icons/im';
import CategoryModal from './CategoryModal';
import useCategory from '../../hooks/useCategory';

const TimelineForm = () => {
  const {
    timelines,
    addTimeline,
    editTimeline,
    scheduleTimelineReminder,
    loading,
  } = useTimeline();
  const [content, setContent] = useState<string>('');
  const [category, setCategory] = useState<string>('Select...');
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);
  const formattedDate = selectedDate ? selectedDate.format('YYYY-MM-DD') : '';
  const formattedTime = selectedTime ? selectedTime.format('hh:mm A') : '';
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const { categories, fetchCategories } = useCategory();
  const [ring, setRing] = useState<boolean>(() => {
    // Read from localStorage or default to true
    const storedRingPreference = localStorage.getItem('ringPreference');
    return storedRingPreference ? storedRingPreference === 'true' : true;
  });
  const [modalType, setModalType] = useState<'add' | 'delete' | undefined>(
    'add'
  );
  const [selectedCategoryForDeletion, setSelectedCategoryForDeletion] =
    useState('');

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const { isMobile, isTablet } = useIsMedia();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const { noteId } = useParams<{ noteId?: string }>();
  const isEdit = noteId != null;
  const pathHistory = useHistory();

  // Whenever the ring state changes, update localStorage
  useEffect(() => {
    localStorage.setItem('ringPreference', ring.toString());
  }, [ring]);

  useEffect(() => {
    const checkFormValidity = () => {
      const errors = validateForm(
        content,
        category,
        selectedDate,
        formattedTime,
        timelines,
        noteId
      );
      setIsFormValid(Object.keys(errors).length === 0);
    };
    checkFormValidity();
  }, [content, category, selectedDate, formattedTime]);
  useEffect(() => {
    if (isEdit && noteId) {
      const timelineToEdit = timelines.find(
        (timeline) => timeline._id === noteId
      );
      if (timelineToEdit) {
        setRing(timelineToEdit.ring ?? false);
      }

      if (timelineToEdit) {
        setContent(timelineToEdit.content);
        setCategory(
          categories.some(
            (cat) =>
              cat.name.toLowerCase() === timelineToEdit.category.toLowerCase()
          )
            ? timelineToEdit.category
            : 'Select...'
        );

        if (timelineToEdit.reminderDate) {
          setSelectedDate(dayjs(timelineToEdit.reminderDate));
        } else {
          setSelectedDate(null);
        }

        // Check if reminderTime is a valid string and convert it to a Dayjs object
        if (
          timelineToEdit.reminderTime &&
          typeof timelineToEdit.reminderTime === 'string'
        ) {
          const parsedTime = parseTime(timelineToEdit.reminderTime);
          setSelectedTime(parsedTime.isValid() ? parsedTime : null);
        } else {
          setSelectedTime(null);
        }
      }
      setIsDataChanged(false);
    }
  }, [isEdit, noteId, timelines]);

  const handleContentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
    setValidationErrors((prevErrors) =>
      validateField(prevErrors, 'content', () => e.target.value.trim() !== '')
    );
    setIsDataChanged(true);
  };

  const handleDateChange = (newDate: Dayjs | null) => {
    setSelectedDate(newDate);
    setIsDataChanged(true);
    // Format the date for validation
    const formattedDate = newDate ? newDate.format('YYYY-MM-DD') : '';
    // Validate and set the new errors
    const newErrors = validateForm(
      content,
      category,
      newDate,
      formattedDate,
      timelines,
      noteId
    );
    setValidationErrors(newErrors);
  };

  // Update the handleTimeChange to convert Dayjs to string
  const handleTimeChange = (newTime: Dayjs | null) => {
    setSelectedTime(newTime);
    setIsDataChanged(true);
    // Format the date and time for validation
    const formattedTime = newTime ? newTime.format('hh:mm A') : '';

    // Validate and set the new errors
    const newErrors = validateForm(
      content,
      category,
      selectedDate,
      formattedTime,
      timelines,
      noteId
    );
    setValidationErrors(newErrors);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newErrors = validateForm(
      content,
      category,
      selectedDate,
      formattedTime,
      timelines,
      noteId
    );
    setValidationErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const timelineData: TimelineData = {
        content,
        category,
        reminderDate: formattedDate,
        reminderTime: formattedTime,
        ring,
      };
      const userDetails = JSON.parse(
        localStorage.getItem('userDetails') || '{}'
      );
      const userId = userDetails._id;

      try {
        let timelineId = noteId;
        if (isEdit && timelineId) {
          await editTimeline(timelineId, timelineData);
          pathHistory.push('/timeline');
        } else {
          const addedTimeline = await addTimeline(timelineData, userId);
          if (addedTimeline) {
            timelineId = addedTimeline._id;
          }
        }

        // Schedule ring alarm if ring is true
        if (ring && timelineId) {
          await scheduleTimelineReminder(timelineId, {
            reminderDate: formattedDate,
            reminderTime: formattedTime,
            ring,
          });
        }

        setContent('');
        setCategory('Select...');
        setSelectedDate(null);
        setSelectedTime(null);
        // Reset the form or navigate away
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  const handleCategoryChange = (value: string | number) => {
    const selectedCategory = value.toString(); // Ensure the value is treated as a string
    if (value === 'Shopping') {
      setContent('• ');
    } else {
      setContent('');
    }
    if (selectedCategory === 'Create new') {
      setModalType('add');
      setSelectedCategoryForDeletion('');
      setShowCategoryModal(true);
    } else {
      setCategory(selectedCategory);
      setShowCategoryModal(false);
    }
  };

  const handleDeleteCategory = (categoryName: string | number) => {
    setSelectedCategoryForDeletion(categoryName.toString());
    setModalType('delete');
    setShowCategoryModal(true);
  };

  const handleNewCategoryAdded = async (newCategoryName: string) => {
    await fetchCategories(); // Ensure this fetch updates the state
    const normalizedCategoryName =
      newCategoryName.charAt(0).toUpperCase() +
      newCategoryName.slice(1).toLowerCase();
    setCategory(normalizedCategoryName); // Use normalized name
    setShowCategoryModal(false);
  };

  const handleContentTimelineChange = (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default Enter behavior (new line)
      if (category === 'Shopping') {
        // Add a new dot on a new line when Enter is pressed
        setContent((prev) => prev + '\n• ');
      }
    }
  };
  const handleRingChange = () => {
    setIsDataChanged(true);
    setRing((prevRing) => !prevRing);
  };

  const handleBack = () => {
    history.back();
  };

  return (
    <>
      {showCategoryModal && (
        <CategoryModal
          onAddCategory={handleNewCategoryAdded}
          onDeleteCategory={fetchCategories}
          setShowCategoryModal={setShowCategoryModal}
          modalType={modalType}
          categoryName={selectedCategoryForDeletion}
        />
      )}

      <Box width={isMobile || isTablet ? '100%' : '450px'} mb={4} p={[2, 2, 3]}>
        <Box mb={3}>
          <Back arrow onClick={handleBack} />
        </Box>
        <Text as="h2" mb={3} bold color={theme.colors.mediumDark}>
          {isEdit ? 'Edit Timeline' : 'New Timeline'}
        </Text>
        {isEdit ? (
          <Text mb={1}>
            Use this form to effortlessly update your existing timelines. Modify
            the category, adjust reminder dates and times, and refine your notes
            to keep your timelines up-to-date and relevant.
          </Text>
        ) : (
          <Text mb={1}>
            This form allows you to conveniently create new timelines. Select a
            category, set reminders, and write down details, simplifying the way
            you organize and track important events.
          </Text>
        )}
        {validationErrors.duplicate && (
          <Text bold mb={2}>
            <ErrorMessage>{validationErrors.duplicate}</ErrorMessage>
          </Text>
        )}

        <Form onSubmit={handleSubmit}>
          <Flex flexDirection="column" alignItems="flex-end">
            <Switch label="Ring" isOn={ring} onClick={handleRingChange} />
          </Flex>
          <Box mb={3}>
            <Select
              focus
              label="Category"
              value={category}
              onChange={handleCategoryChange}
              onDelete={handleDeleteCategory}
              required
              options={[
                ...categories.map((cat) => ({
                  label: cat.name,
                  value: cat.name,
                })),
                {
                  label: (
                    <Text as="small" color={theme.colors.supportingBlue}>
                      + Create new
                    </Text>
                  ),
                  value: 'Create new',
                },
              ]}
              placeholder="Select a category..."
            />
            {validationErrors.category && (
              <ErrorMessage>{validationErrors.category}</ErrorMessage>
            )}
          </Box>

          <Box mb={4}>
            <DateAndTimePicker
              selectedDate={selectedDate ? dayjs(selectedDate) : null}
              selectedTime={selectedTime ? dayjs(selectedTime) : null}
              onDateChange={handleDateChange}
              onTimeChange={handleTimeChange}
              validationErrors={validationErrors}
            />
            {!isMobile && !isTablet && validationErrors.dateTime && (
              <ErrorMessage>{validationErrors.dateTime}</ErrorMessage>
            )}
          </Box>
          <Box mb={3}>
            <TextArea
              required
              label="Timeline"
              rows={category === 'Shopping' ? 8 : 5}
              name="Timeline"
              value={content}
              onKeyDown={handleContentTimelineChange}
              onChange={handleContentChange}
              placeholder="Add a timeline..."
            />
            {validationErrors.content && (
              <ErrorMessage>{validationErrors.content}</ErrorMessage>
            )}
          </Box>

          <Box mb={3}>
            <Button
              type="submit"
              size="medium"
              bold
              disabled={loading || !isFormValid || (isEdit && !isDataChanged)}
            >
              {loading ? (
                <ImSpinner8 size={20} style={{ verticalAlign: 'middle' }} />
              ) : isEdit ? (
                'Update Timeline'
              ) : (
                'Add Timeline'
              )}
            </Button>
          </Box>
          <Box>
            <Button
              size="medium"
              onClick={handleBack}
              bold
              styleType="secondary"
            >
              {isEdit ? 'Back to Timeline' : 'Back to Dashboard'}
            </Button>
          </Box>
        </Form>
      </Box>
    </>
  );
};

export default TimelineForm;

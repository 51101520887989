import styled, { css } from 'styled-components';
import { ButtonProps } from './types';
import { rem } from 'polished';
import { space } from 'styled-system';
import theme from '../../theme';

const StyleButton = styled.button<ButtonProps>`
  ${space};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-radius: ${rem(6)};
  border: none;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  color: theme.colors.white;
  padding: ${rem(10)} ${rem(15)};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '100%')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-weight: ${({ bold }) => (bold ? '700' : '500')};

  &:hover,
  &:focus {
    outline: 0;
  }

  &:active {
    transform: scale(0.98);
  }

  ${({ styleType, disabled }) => {
    // Define style variables
    let backgroundColor = theme.colors.supportingBlueLight;
    let borderColor = theme.colors.supportingBlueLight;
    let hoverBackgroundColor = theme.colors.supportingBlueLight;
    let textColor = theme.colors.white;

    // Ensure the button is properly disabled
    if (disabled) {
      backgroundColor = theme.colors.supportingBlueLight;
      borderColor = theme.colors.supportingBlueLight;
      hoverBackgroundColor = theme.colors.supportingBlueLight;
      textColor = theme.colors.offBlack;
    }

    // Customize colors based on styleType
    switch (styleType) {
      // Add or modify cases as needed
      case 'secondary':
        backgroundColor = theme.colors.light;
        borderColor = theme.colors.light;
        hoverBackgroundColor = theme.colors.light;
        textColor = theme.colors.veryDark;
        break;
      case 'danger':
        backgroundColor = theme.colors.primaryRed;
        borderColor = theme.colors.primaryRed;
        hoverBackgroundColor = theme.colors.primaryRed;
        break;
      case 'secondaryOutline':
        backgroundColor = theme.colors.white;
        borderColor = theme.colors.light;
        hoverBackgroundColor = theme.colors.light;
        textColor = theme.colors.veryDark;
        break;

      default:
        break;
    }

    // Return the customized CSS
    return css`
      background-color: ${backgroundColor};
      border: 1px solid ${borderColor};
      color: ${textColor};

      &:hover,
      &:focus {
        background-color: ${hoverBackgroundColor};
      }
    `;
  }}

  ${({ size }) => {
    switch (size) {
      // Add or modify sizes as needed
      case 'medium':
        return css`
          font-size: ${rem(16)};
          padding: ${rem(10)} ${rem(20)};
        `;
      case 'large':
        return css`
          font-size: ${rem(18)};
          padding: ${rem(12)} ${rem(25)};
        `;
      default:
        return css`
          font-size: ${rem(12)};
          padding: ${rem(6)} ${rem(12)};
        `;
    }
  }}
`;

export default StyleButton;

import { useState } from 'react';
import apiCaller from '../../Utils/apiCaller';
import { handleError } from '../../Utils/helper';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';

type CheckPhoneResponse = {
  exists: boolean;
  phoneNumber: string;
};

const useCheckPhone = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<CheckPhoneResponse | null>(null);
  const apiBaseUrl = getApiBaseUrl();

  const checkPhone = async (phoneNumber: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<CheckPhoneResponse>({
        endpoint: `${apiBaseUrl}/auth/checkPhone?phoneNumber=${phoneNumber}`,
        method: 'GET',
      });
      setData(response);
    } catch (err) {
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  return {
    checkPhone,
    loading,
    error,
    data,
  };
};

export default useCheckPhone;

import styled from 'styled-components';
import { Box, Text } from '../../../storyBook';
import { rem } from 'polished';
import { FaEllipsisV } from 'react-icons/fa';

export const Menu = styled(Box)`
  position: absolute;
  right: 0;
  margin-right: ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.light};
  border-radius: ${rem(5)};
  padding: ${rem(5)};

  ${({ theme }) => theme.media.desktop} {
    margin-right: ${({ theme }) => theme.spacing.md};
  }
  z-index: 1;
`;

export const MenuItem = styled(Text)`
  padding: ${rem(5)};
  width: ${rem(150)};
  font-size: ${rem(12)};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;

  span {
    margin-left: ${rem(5)};
  }

  &:last-child {
    color: ${({ theme }) => theme.colors.primaryRed};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.ultraLight};
  }
`;

export const RotatableIconContainer = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  ${(props) => (props.isOpen ? 'transform: rotate(90deg);' : '')}
`;

export const RotatableIcon = styled(FaEllipsisV)`
  transition: transform 0.3s ease;
  color: ${(props) => props.theme.colors.medium};

  &:focus {
    outline: none;
  }
`;

import React, { useState, useRef, forwardRef, useEffect } from 'react';
import { useClickAway } from 'react-use';
import { Box } from '../Grid';
import Text from '../Text';
import { Props } from './types';
import { Container, StyledLabel, Asterisk } from './style';

const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      rows = 4,
      onChange,
      name,
      value = '',
      resize = false,
      error,
      label,
      required,
      ...props
    }: Props,
    forwardedRef
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const innerRef = useRef<HTMLTextAreaElement>(null); // Create an inner ref

    // Handle click away
    useClickAway(innerRef, () => {
      if (innerRef.current) {
        // Check if ref is not null
        setIsFocused(false);
      }
    });

    // Forward the innerRef to the actual textarea element
    useEffect(() => {
      if (!forwardedRef) return;

      if (typeof forwardedRef === 'function') {
        forwardedRef(innerRef.current);
      } else {
        forwardedRef.current = innerRef.current;
      }
    }, [forwardedRef]);

    return (
      <>
        {label && (
          <StyledLabel htmlFor={name}>
            {label} {required && <Asterisk>*</Asterisk>}
          </StyledLabel>
        )}
        <Container
          {...props}
          resize={resize}
          rows={rows}
          onChange={onChange}
          value={value || ''}
          name={name}
          onKeyDown={props.onKeyDown}
          isFocused={isFocused}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          ref={innerRef} // Use the inner ref here
        />
        {error && (
          <Box mt={0} pl={0}>
            <Text as="small" color="statusError">
              {error}
            </Text>
          </Box>
        )}
      </>
    );
  }
);

export default TextArea;

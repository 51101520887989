import { useEffect } from 'react';
// import { registerPushSubscription } from '../src/component/Utils/pushNotificationUtils';
const ServiceWorkerManager = () => {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log(
            'Service Worker registered with scope:',
            registration.scope
          );
          // Do not call registerPushSubscription here
        })
        .catch((error) => {
          console.log('Service Worker registration failed:', error);
        });
    }
  }, []);

  return null; // This component does not render anything
};

export default ServiceWorkerManager;

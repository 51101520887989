import { ColorsMap } from './types'; // Importing types from the types.ts file

const colors: ColorsMap = {
  bodyBackground: '#f0f2f5',
  cardBackground: '#f9f9f9',
  primary: '#2AFD80',
  primaryAlternate: '#00E97A',
  primaryLight: '#E5FFF1',
  secondary: '#000000',
  white: '#FFFFFF',
  offBlack: '#201F1F',
  veryDark: '#272727',
  mediumDark: '#5D5D5D',
  medium: '#A0A0A0',
  light: '#E8E8E8',
  ultraLight: '#f5f6f7',
  hint: '#E5FFF1',
  borderColor: '#dcdcdc',
  primaryGreen: '#00FF7A',
  primaryGreen50: '#EDFFF6',
  supportingBlue: '#3c66a3',
  supportingBlueLight: '#1877f2',
  supportingBlue50: '#6995d7',
  primaryRed: '#FF0000',
  supportingRed: '#E70952',
  supportingRed50: '#FDEEF3',
  success: '#32CD32',
  pending: '#FFA500',
};

export default colors;

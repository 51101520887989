import { useMemo } from 'react';
import { ButtonProps } from './types';
import StyleButton from './style';

const Button: React.FC<ButtonProps> = ({
  children,
  styleType = 'primary',
  size = 'small',
  type = 'button',
  onClick,
  disabled = false,
  fullWidth = false,
  bold = false,
  id,
  ...rest
}) => {
  const renderButton = useMemo(() => {
    return (
      <StyleButton
        styleType={styleType}
        size={size}
        onClick={onClick}
        disabled={disabled}
        fullWidth={fullWidth}
        bold={bold}
        id={id}
        type={type}
        {...rest}
      >
        {children}
      </StyleButton>
    );
  }, [children, styleType, size, onClick, disabled, id, rest]);
  return renderButton;
};

export default Button;

import { TextInput } from '../../../../storyBook';
import {
  firstNameValidation,
  lastNameValidation,
  emailValidation,
  phoneNumberValidation,
  passwordValidation,
  confirmPasswordValidation,
} from '../../../Utils/validation';

import theme from '../../../../theme';
import { StageOneProps, StageTwoProps, StageThreeProps } from './types';

export const StageOne: React.FC<StageOneProps> = ({
  form,
  handleInputChange,
  setShowErrorMessage,
}) => (
  <>
    <TextInput
      autoFocus
      background={theme.colors.ultraLight}
      placeholder="First Name"
      value={form.firstName}
      onChange={handleInputChange('firstName')}
      validation={firstNameValidation}
      onValidation={setShowErrorMessage}
    />
    <TextInput
      background={theme.colors.ultraLight}
      placeholder="Last Name"
      value={form.lastName}
      onChange={handleInputChange('lastName')}
      validation={lastNameValidation}
      onValidation={setShowErrorMessage}
    />
  </>
);

export const StageTwo: React.FC<StageTwoProps> = ({
  form,
  emailData,
  phoneData,
  handleInputChange,
  setShowErrorMessage,
}) => (
  <>
    <TextInput
      autoFocus
      background={theme.colors.ultraLight}
      placeholder="Email"
      value={form.email}
      onChange={handleInputChange('email')}
      validation={(value) => emailValidation(value, emailData?.exists)}
      onValidation={setShowErrorMessage}
    />
    <TextInput
      background={theme.colors.ultraLight}
      placeholder="Mobile Number"
      value={form.phoneNumber}
      onChange={handleInputChange('phoneNumber')}
      validation={(value) => phoneNumberValidation(value, phoneData?.exists)}
      onValidation={setShowErrorMessage}
    />
  </>
);

export const StageThree: React.FC<StageThreeProps> = ({
  form,
  handleInputChange,
  setShowErrorMessage,
}) => (
  <>
    <TextInput
      autoFocus
      background={theme.colors.ultraLight}
      placeholder="Password"
      type="password"
      value={form.password}
      onChange={handleInputChange('password')}
      validation={passwordValidation}
      onValidation={setShowErrorMessage}
    />
    <TextInput
      background={theme.colors.ultraLight}
      placeholder="Confirm password"
      type="password"
      value={form.confirmPassword}
      onChange={handleInputChange('confirmPassword')}
      validation={(value) => confirmPasswordValidation(form.password, value)}
      onValidation={setShowErrorMessage}
    />
  </>
);

import { SwithchProps } from './types';
import { Toggle, SwitchContainer, Label } from './style';

const Switch: React.FC<SwithchProps> = ({ isOn, onClick, label }) => (
  <>
    {label && <Label>{label}</Label>}
    <SwitchContainer isOn={isOn} onClick={onClick}>
      <Toggle isOn={isOn}>{isOn ? 'ON' : 'OFF'}</Toggle>
    </SwitchContainer>
  </>
);

export default Switch;

import React from 'react';
import { Main, LeftColumn, RightColumn, Inner } from './style';
import { LayoutProps } from './types';

const LayoutBase: React.FC<LayoutProps> = ({
  children,
  navigition,
  withBackground = false,
  withBackgroundPadding = false,
  mobileBottomPadding = false,
}) => {
  return (
    <Inner mobileBottomPadding={mobileBottomPadding} role="main">
      <Main withBackground={withBackground}>
        {navigition && <LeftColumn>{navigition}</LeftColumn>}
        <RightColumn
          withBackground={withBackground}
          withBackgroundPadding={withBackgroundPadding}
        >
          {children}
        </RightColumn>
      </Main>
    </Inner>
  );
};

export default LayoutBase;

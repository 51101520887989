import SnapNoteList from './SnapNoteList';

const SnapNotes = () => {
  return (
    <div>
      <SnapNoteList />
    </div>
  );
};

export default SnapNotes;

import styled from 'styled-components';
import { FaEllipsisV } from 'react-icons/fa';

// Styled components
export const RotatableIconContainer = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  ${(props) => (props.isOpen ? 'transform: rotate(90deg);' : '')}
`;

export const RotatableIconStyled = styled(FaEllipsisV)`
  transition: transform 0.3s ease;
  color: ${(props) => props.theme.colors.medium};

  &:focus {
    outline: none;
  }
`;

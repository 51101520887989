/* eslint-disable @typescript-eslint/no-use-before-define */
import { createContext, useState, useEffect } from 'react';
import apiCaller from '../../Utils/apiCaller';
import { handleError } from '../../Utils/helper';
import { notify } from '../../../storyBook/TostNotification';
import { SnapNote } from '../../types';
import { SnapNoteContextType, ChildrenProps, UserQuota } from './types';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';
import useAuth from '../../hooks/useAuth';

export const SnapNoteContext = createContext<SnapNoteContextType | null>(null);

export const SnapNoteProvider = ({ children }: ChildrenProps) => {
  const [snapNotes, setSnapNotes] = useState<SnapNote[]>([]);
  const [selectedSnapNote, setSelectedSnapNote] = useState<SnapNote | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [quota, setQuota] = useState<UserQuota>({
    storageUsed: 0,
    storageQuota: 0,
  });
  const { isAuthenticated } = useAuth();

  const apiBaseUrl = getApiBaseUrl();

  const fetchAllSnapNotes = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<SnapNote[]>({
        endpoint: `${apiBaseUrl}/api/snapNotes`,
        method: 'GET',
        includeToken: true,
      });
      setSnapNotes(response);
    } catch (err) {
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  const getSnapNote = async (id: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<SnapNote>({
        endpoint: `${apiBaseUrl}/api/snapNotes/${id}`,
        method: 'GET',
        includeToken: true,
      });
      setSelectedSnapNote(response); // Set the fetched snap note
    } catch (err) {
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  const addSnapNote = async (newSnapNoteData: FormData) => {
    setLoading(true);
    setError(null);
    try {
      await apiCaller({
        endpoint: `${apiBaseUrl}/api/snapNotes`,
        method: 'POST',
        body: newSnapNoteData,
        includeToken: true,
      });
      notify({ message: 'Snapnote added successfully', type: 'success' });

      await fetchAllSnapNotes();
      await getUserQuota();
    } catch (err) {
      notify({ message: error || 'An error occurred', type: 'error' });
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  const editSnapNote = async (snapNoteId: string, updatedData: FormData) => {
    setLoading(true);
    setError(null);
    try {
      await apiCaller({
        endpoint: `${apiBaseUrl}/api/snapNotes/${snapNoteId}`,
        method: 'PUT',
        body: updatedData,
        includeToken: true,
      });
      notify({ message: 'Snapnote updated successfully', type: 'success' });
      await fetchAllSnapNotes();
      await getUserQuota();
    } catch (err) {
      notify({ message: error || 'An error occurred', type: 'error' });
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  const deleteSnapNote = async (snapNoteId: string) => {
    setLoading(true);
    setError(null);
    try {
      await apiCaller({
        endpoint: `${apiBaseUrl}/api/snapNotes/${snapNoteId}`,
        method: 'DELETE',
        includeToken: true,
      });
      notify({ message: 'Snapnote deleted successfully', type: 'success' });
      await fetchAllSnapNotes();
      await getUserQuota();
    } catch (err) {
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  const removeSnapNoteFromTimeline = async (snapNoteId: string) => {
    setLoading(true);
    setError(null);
    try {
      await apiCaller({
        endpoint: `${apiBaseUrl}/api/snapNotes/${snapNoteId}/remove-from-timeline`,
        method: 'POST',
        includeToken: true,
      });
      notify({
        message: 'Snapnote removed from timeline successfully',
        type: 'success',
      });
      await fetchAllSnapNotes();
    } catch (err) {
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  const toggleStar = async (snapNoteId: string) => {
    setLoading(true);
    setError(null);
    try {
      await apiCaller({
        endpoint: `${apiBaseUrl}/api/snapNotes/${snapNoteId}/star`,
        method: 'POST',
        includeToken: true,
      });
      await fetchAllSnapNotes();
    } catch (err) {
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  const getUserQuota = async () => {
    try {
      const response = await apiCaller<UserQuota>({
        endpoint: `${apiBaseUrl}/api/user/quota`,
        method: 'GET',
        includeToken: true,
      });
      setQuota(response);
    } catch (err) {
      console.error('Failed to fetch user quota', err);
    }
  };

  const updateSnapNoteImage = async (
    snapNoteId: string,
    updatedImageData: FormData
  ) => {
    setLoading(true);
    setError(null);
    try {
      await apiCaller({
        endpoint: `${apiBaseUrl}/api/snapNotes/${snapNoteId}/image`,
        method: 'PUT',
        body: updatedImageData,
        includeToken: true,
      });
      notify({ message: 'Image updated successfully', type: 'success' });
      await fetchAllSnapNotes();
      await getUserQuota();
    } catch (err) {
      notify({ message: error || 'An error occurred', type: 'error' });
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchAllSnapNotes(); // Call the fetchAllSnapNotes function
      getUserQuota(); // Fetch user quota when authenticated
    }
  }, [isAuthenticated]);

  return (
    <SnapNoteContext.Provider
      value={{
        snapNotes,
        selectedSnapNote,
        fetchAllSnapNotes,
        removeSnapNoteFromTimeline,
        updateSnapNoteImage,
        getSnapNote,
        addSnapNote,
        editSnapNote,
        deleteSnapNote,
        toggleStar,
        loading,
        error,
        quota,
        getUserQuota,
      }}
    >
      {children}
    </SnapNoteContext.Provider>
  );
};

import styled, { keyframes } from 'styled-components';
import { Box } from '../../../storyBook/Grid';

const moveRight = keyframes`
  0% {
    left: -150px;
  }
  100% {
    left: 100%;
  }
`;

export const LoaderContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 4px;
  overflow: hidden;
`;

export const LoaderBar = styled(Box)<{ isLoading: boolean }>`
  position: absolute;
  top: 0;
  left: -150px;
  width: 100%;
  height: 2px;
  background-color: ${(props) => props.theme.colors.supportingBlueLight};
  animation: ${moveRight} 2s linear infinite;
  animation-play-state: ${(props) => (props.isLoading ? 'running' : 'paused')};
`;

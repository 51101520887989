import styled from 'styled-components';
import { LinkText } from '../../_common/style';

export const CaptureButton = styled.button`
  background-color: ${({ theme }) => theme.colors.medium};
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.mediumDark};
  bottom: 0;
  cursor: pointer;
  height: 56px;
  left: 50%;
  margin-left: -28px;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  position: absolute;
  width: 56px;
  z-index: 1;
`;

export const StyleLinkText = styled(LinkText)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.veryDark};
  text-decoration: none;
  font-weight: 600;
  &:hover {
    color: ${({ theme }) => theme.colors.mediumDark};
  }
`;

export const StyleInput = styled.input`
  display: none;
`;

export const StyleCanvas = styled.canvas`
  display: none;
`;

import { useState, useEffect } from 'react';
import { Card, Flex } from '../../../storyBook';
import theme from '../../../theme';
import useIsMedia from '../../../storyBook/hooks/useIsMedia';
import HeaderSignup from '../../Header/SignupHeader';
import { useHistory } from 'react-router-dom';
import useSignup from '../../hooks/useRegister';
import { notify } from '../../../storyBook/TostNotification';
import Loader from '../../_common/Loader';
import useCheckEmail from '../../hooks/useCheckEmail';
import useCheckPhone from '../../hooks/useCheckPhone';
import FormContent from './FromContent';

const SignUp: React.FC = () => {
  const { isTablet, isMobile, isDesktop } = useIsMedia();
  const combined = isTablet || isMobile;

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
  });

  const [stage, setStage] = useState(1);
  const [hasMovedForward, setHasMovedForward] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const history = useHistory();

  const { signup, loading, error, data } = useSignup();
  const { checkEmail, data: emailData } = useCheckEmail();
  const { checkPhone, data: phoneData } = useCheckPhone();

  useEffect(() => {
    if (showErrorMessage) {
      if (error) notify({ message: error, type: 'error' });
      if (data?.status) notify({ message: data.status, type: 'success' });
    }
  }, [error, data, showErrorMessage]);

  useEffect(() => {
    if (emailData) {
      setEmailValid(!emailData.exists);
    }
  }, [emailData]);

  useEffect(() => {
    if (phoneData) {
      setPhoneValid(!phoneData.exists);
    }
  }, [phoneData]);

  const handleInputChange =
    (field: keyof typeof form) =>
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      // Normalize email to lowercase
      if (field === 'email') {
        value = value.toLowerCase();
        await checkEmail(value);
        setEmailValid(!emailData?.exists);
      }
      // Check phone number existence
      if (field === 'phoneNumber' && value) {
        await checkPhone(value);
        setPhoneValid(!phoneData?.exists);
      }
      setForm((prevForm) => ({ ...prevForm, [field]: value }));
    };

  const handleBack = () => {
    if (hasMovedForward && stage > 1) {
      setStage(stage - 1);
    } else {
      history.goBack();
    }
  };

  const handleNext = () => {
    if (stage < 3) {
      setStage(stage + 1);
      setHasMovedForward(true);
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    signup(form);
    setShowErrorMessage(true);
    setTimeout(() => {
      history.push('/login');
    }, 5000);
  };

  return (
    <>
      <HeaderSignup showJoinText onBackClick={() => handleBack()} />
      {loading && <Loader isLoading={loading} />}

      <Flex
        justifyContent="center"
        alignItems="center"
        height={combined ? '100%' : '100vh'}
        backgroundColor={combined ? undefined : theme.colors.bodyBackground}
        flexDirection={combined ? 'column' : 'row'}
        m={[2, 2, 4, 5]}
        pt={[2, 2, 4, 0]}
      >
        <Card
          border={isDesktop}
          width={(isTablet && '100%') || (isMobile && '100%') || '40%'}
        >
          <FormContent
            stage={stage}
            form={form}
            emailData={emailData}
            emailValid={emailValid}
            phoneData={phoneData}
            phoneValid={phoneValid}
            setShowErrorMessage={setShowErrorMessage}
            handleInputChange={handleInputChange}
            handleNext={handleNext}
            handleSubmit={handleSubmit}
          />
        </Card>
      </Flex>
    </>
  );
};

export default SignUp;

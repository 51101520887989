import { BreakpointNamesMap } from '../types';

const breakpoints: string[] = [
  '360px',
  '504px',
  '671px',
  '920px',
  '1050px',
  '1200px',
];

const breakpointNames: BreakpointNamesMap = {
  smallPhone: breakpoints[0],
  phone: breakpoints[1],
  smallTablet: breakpoints[2],
  tablet: breakpoints[3],
  desktop: breakpoints[4],
  giant: breakpoints[5],
};

export { breakpointNames, breakpoints };

import { useState } from 'react';
import apiCaller from '../../Utils/apiCaller';
import { handleError } from '../../Utils/helper';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';

type ResetPasswordProps = {
  userId: string;
  newPassword: string;
};

type ResetPasswordResponse = {
  status: string;
};

const useResetPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<ResetPasswordResponse | null>(null);
  const apiBaseUrl = getApiBaseUrl();

  const resetPassword = async (
    credentials: ResetPasswordProps
  ): Promise<ResetPasswordResponse | null> => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<ResetPasswordResponse>({
        endpoint: `${apiBaseUrl}/auth/reset-password`,
        method: 'POST',
        body: credentials,
      });
      setData(response);
      return response;
    } catch (err) {
      handleError(err, setError);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    resetPassword,
    loading,
    error,
    data,
  };
};

export default useResetPassword;

import { createContext, useState, useEffect } from 'react';
import apiCaller from '../../Utils/apiCaller';
import { handleError } from '../../Utils/helper';
import { notify } from '../../../storyBook/TostNotification';
import { Password } from '../../types';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';
import useAuth from '../../hooks/useAuth';

import {
  PasswordData,
  PasswordProviderProps,
  PasswordContextType,
} from './types';

export const PasswordContext = createContext<PasswordContextType | null>(null);

export const PasswordProvider = ({ children }: PasswordProviderProps) => {
  const [passwords, setPasswords] = useState<Password[]>([]);
  const [selectedPasswordId, setSelectedPasswordId] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { isAuthenticated } = useAuth();

  const apiBaseUrl = getApiBaseUrl();

  const selectPassword = (id: string) => {
    setSelectedPasswordId(id);
  };

  const clearSelectedPassword = () => {
    setSelectedPasswordId(null);
  };

  // Fetch all passwords
  const fetchAllPasswords = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<Password[]>({
        endpoint: `${apiBaseUrl}/api/passwords`,
        method: 'GET',
        includeToken: true,
      });
      setPasswords(response);
    } catch (err) {
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  // Add a password
  const addPassword = async (newPasswordData: PasswordData) => {
    setLoading(true);
    setError(null);
    try {
      await apiCaller<Password>({
        endpoint: `${apiBaseUrl}/api/passwords`,
        method: 'POST',
        body: newPasswordData,
        includeToken: true,
      });
      notify({ message: 'Password added successfully', type: 'success' });
      await fetchAllPasswords();
    } catch (err) {
      notify({
        message: 'An error occurred while adding password',
        type: 'error',
      });
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  // Edit a password
  const editPassword = async (
    passwordId: string,
    updatedData: PasswordData
  ) => {
    setLoading(true);
    setError(null);
    try {
      await apiCaller<Password>({
        endpoint: `${apiBaseUrl}/api/passwords/${passwordId}`,
        method: 'PUT',
        body: updatedData,
        includeToken: true,
      });
      await fetchAllPasswords();
      notify({ message: 'Password edited successfully', type: 'success' });
    } catch (err) {
      notify({
        message: 'An error occurred while editing password',
        type: 'error',
      });
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  // Delete a password
  const deletePassword = async (passwordId: string) => {
    setLoading(true);
    setError(null);
    try {
      await apiCaller({
        endpoint: `${apiBaseUrl}/api/passwords/${passwordId}`,
        method: 'DELETE',
        includeToken: true,
      });
      notify({ message: 'Password deleted successfully', type: 'success' });
      await fetchAllPasswords();
    } catch (err) {
      notify({
        message: 'An error occurred while deleting password',
        type: 'error',
      });
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  // Fetch all passwords on initial render
  useEffect(() => {
    if (isAuthenticated) {
      fetchAllPasswords();
    }
  }, [isAuthenticated]);

  return (
    <PasswordContext.Provider
      value={{
        passwords,
        selectedPasswordId,
        fetchAllPasswords,
        selectPassword,
        clearSelectedPassword,
        addPassword,
        editPassword,
        deletePassword,
        loading,
        error,
      }}
    >
      {children}
    </PasswordContext.Provider>
  );
};

import styled, { keyframes } from 'styled-components';
import { FaPlus } from 'react-icons/fa';

// Animation for the hover effect
const pulse = keyframes`
  from {
    box-shadow: 0 0 0 0 rgba(103, 123, 236, 0.3);
  }
  to {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
  }
`;

// Styled component for the outer layer
export const ButtonWrapper = styled.div`
  position: fixed;
  right: 20px;
  bottom: 100px;
  display: inline-block;
  padding: 4px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 8px 15px rgba(6, 24, 59, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  animation: ${pulse} 3s infinite;
  z-index: 1000;
  ${({ theme }) => theme.media.tablet} {
    position: fixed;
    bottom: 50px;
  }
`;

// Styled component for the button itself
export const StyledButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #1877f2; /* Your button's background color */
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1); // Slightly enlarges the button on hover
  }
`;

export const Icon = styled(FaPlus)`
  width: 18px;
  height: 18px;
`;

import styled from 'styled-components';
import { Box, Flex, Text } from '../../storyBook';

interface DashboardProps {
  backgroundImage: string;
}

export const DashboardContainer = styled(Box)<DashboardProps>`
  width: 100%;
  height: 300px;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 100px;
  z-index: 0;
`;

export const GreetingContainer = styled(Box)`
  position: absolute;
  top: 50px;
  left: 70px;
  z-index: 1;
`;

export const GreetingLine = styled(Text)`
  color: ${({ color }) => color};
  font-size: 40px;
  &:first-child {
    margin-bottom: 10px;
  }
`;
export const NameLine = styled(GreetingLine)`
  color: ${({ color }) => color};
  font-size: 40px;
`;

export const CardContainer = styled(Box)`
  width: 95%;
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  ${({ theme }) => theme.media.tablet} {
    position: absolute;
    bottom: -70px;
  }

  ${({ theme }) => theme.media.smallTablet} {
    position: absolute;
    bottom: -70px;
  }
`;

export const ButtonContainer = styled(Box)<{ timelinesCount: number }>`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: ${({ timelinesCount }) => (timelinesCount > 0 ? '35px' : '0px')};
  ${({ theme }) => theme.media.desktop} {
    justify-content: ${({ timelinesCount }) =>
      timelinesCount > 0 ? 'flex-start' : 'center'};
    align-items: center;
    margin-top: 0px;
    gap: 20px;
  }
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  &:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
  }
  ${({ theme }) => theme.media.desktop} {
    padding: 15px 20px;
  }
`;

export const ButtonText = styled.div`
  text-align: left;
`;

export const ButtonMainText = styled(Text)`
  font-size: 18px;
  padding: 2px;
`;

export const ButtonSubText = styled(Text)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.medium};
  font-family: 'Futura';
`;
export const TimelineContainer = styled(Flex)`
  position: absolute;
  top: 10px;

  ${({ theme }) => theme.media.desktop} {
    display: flex;
    align-items: center;
    margin-left: auto;
    position: relative;
    top: 0;
  }
`;

export const Badge = styled(Text)`
  background-color: ${({ theme }) => theme.colors.supportingBlue};
  color: ${({ theme }) => theme.colors.white};
  padding: 2px 10px;
  border-radius: 10px;
  margin-left: 10px;
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px; // This sets the gap between the cards
  padding: 20px; // This adds padding around the entire grid
`;

import styled from 'styled-components';

export const ArrowContainer = styled.span`
  position: relative;
  vertical-align: middle;
  display: inline-block;
  transition: transform 0.2s ease;
  transform: translateX(0%);

  svg {
    vertical-align: middle;
    display: inline-block;
  }
`;

export const Container = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: transparent;
  outline: 0;
  border: 0;
  padding: 0;

  &:hover {
    ${ArrowContainer} {
      transform: translateX(-20%);
    }
  }
`;

export const BackText = styled.span`
  font-size: 18px;
  padding-left: ${({ theme }): string => theme.spacing.sm};
  vertical-align: middle;
  display: inline-block;
  line-height: 1;
`;

import styled from 'styled-components';

export const Button = styled.button<{
  isAutoBackground: boolean;
  isFullWidth: boolean;
}>`
  outline: none;
  background-color: ${({ theme, isAutoBackground }) =>
    isAutoBackground ? theme.colors.primaryLight : theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 4px;
  height: 30px;
  display: flex;
  gap: 5px;
  width: ${(props) => (props.isFullWidth ? '100%' : 'auto')};
  min-width: 24px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  color: theme.colors.text;

  &:not(:disabled):hover {
    background-color: theme.colors.hover;
    outline: 1px solid theme.colors.hover;
    outline-offset: 2px;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.supportingBlueLight};
    outline-offset: 2px;
  }
`;

import { useContext } from 'react';
import { SnapNoteContext } from '../../Context/SnapNoteContext';
import { SnapNoteContextType } from '../../Context/SnapNoteContext/types';

const useSnapNote = (): SnapNoteContextType => {
  const context = useContext(SnapNoteContext);

  if (context === null) {
    throw new Error('useSnapNote must be used within an SnapNoteProvider');
  }

  return {
    ...context,
  };
};

export default useSnapNote;

import styled from 'styled-components';
import { Text } from '../../storyBook';

export const ListContents = styled(Text)`
  color: ${({ color, theme }) =>
    color ? '#7B1FA2' : theme.colors.supportingBlueLight};
  cursor: pointer;
`;

export const TextStyled = styled(Text)`
  text-align: justify;
`;

import styled from 'styled-components';
import { Box } from '../../../storyBook';
import { NavLink } from 'react-router-dom';

// Shared Item style for both Mobile and Desktop
export const NavigationItem = styled(Box)`
  display: flex;
  align-items: center;
  color: gray;

  &:hover {
    color: black;
  }

  svg {
    margin-right: 8px;
  }
`;

// Updated Mobile styles
export const Nav = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 8px 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.light};
  z-index: 10;
`;

export const IconText = styled.span`
  font-size: 12px;
  padding-top: 0.5rem;
`;

export const IconContainer = styled.div`
  display: grid;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing.sm};
`;
// Wrapping the MobileItem with NavLink for active state handling

export const MobileItem = styled(NavigationItem)`
  flex-direction: column;
  text-align: center;
  transition: box-shadow 0.3s ease; // Smooth transition for the box-shadow

  svg {
    margin-bottom: 4px;
  }
`;

export const MobileItemLink = styled(NavLink)`
  text-decoration: none;

  &:hover ${MobileItem}, &.active ${MobileItem} {
    border-top: 4px solid ${({ theme }) => theme.colors.supportingBlueLight};
  }
`;

import React, { useState, useRef, FormEvent } from 'react';
import useFeedback from '../hooks/useFeedback';
import {
  Box,
  Button,
  TextArea,
  Text,
  Select,
  useIsMedia,
  Back,
} from '../../storyBook';
import { feedbackOptionsList as optionsList } from '../Utils/constants';
import { FeedbackCategory } from '../types';
import StarRating from './StarRating';
import { Props } from '../../storyBook/Select/types';
import { useHistory } from 'react-router-dom';
import { notify } from '../../storyBook/TostNotification';
import { ImSpinner8 } from 'react-icons/im';
import ReCAPTCHA from 'react-google-recaptcha';
import Loader from '../_common/Loader';

const AddFeedback = () => {
  const { addFeedback, loading } = useFeedback();
  const [content, setContent] = useState('');
  const [images, setImages] = useState<File[]>([]);
  const [rating, setRating] = useState(0);
  const [category, setCategory] = useState<FeedbackCategory>('Select...');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { isMobile, isTablet } = useIsMedia();
  const pathHistory = useHistory();
  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
  const userId = userDetails._id;
  const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  };
  const handleCategoryChange = (value: Props['value']) => {
    setCategory(value as FeedbackCategory);
  };
  if (loading) {
    return <Loader isLoading={loading} />;
  }
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length) {
      setImages(Array.from(files));
      notify({ message: 'Images uploaded successfully', type: 'success' });
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!recaptchaRef.current) {
      console.error('reCAPTCHA reference is not set');
      notify({ message: 'reCAPTCHA not initialized', type: 'error' });
      return;
    }

    try {
      const token = recaptchaRef.current.getValue();
      if (!token) {
        notify({
          message: 'Failed to verify CAPTCHA, please try again.',
          type: 'error',
        });
        return;
      }

      const formData = new FormData();
      formData.append('userId', userId);
      formData.append('content', content);
      formData.append('rating', String(rating));
      formData.append('category', category);
      images.forEach((image) => formData.append('images', image));
      formData.append('token', token);
      formData.append('action', 'add_feedback');

      await addFeedback(formData, token);

      // Clear form fields after successful submission
      setContent('');
      setRating(0);
      setCategory('Select...');
      setImages([]);
    } catch (error) {
      notify({
        message: 'An error occurred during feedback submission.',
        type: 'error',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box width={isMobile || isTablet ? '100%' : '450px'} p={[2, 2, 3]}>
        <Box mb={3}>
          <Back arrow onClick={() => pathHistory.push('/profile')} />
        </Box>
        <Text as="h2" mb={2}>
          Add Feedback
        </Text>
        <Text mb={3}>
          Please share your feedback to help us improve our services. Your input
          is greatly appreciated!
        </Text>
        <Box mb={3}>
          <Select
            focus
            required
            label="Category"
            value={category}
            options={optionsList}
            onChange={handleCategoryChange}
            placeholder="Select a category..."
          />
        </Box>
        <Box mb={3}>
          <TextArea
            name="content"
            label="Feedback Content"
            value={content}
            onChange={handleContentChange}
            placeholder="Enter your feedback here..."
            required
          />
        </Box>
        <Box mb={4}>
          <Text mb={1} style={{ fontWeight: '500', fontSize: '14px' }}>
            Rate Us
          </Text>
          <StarRating rating={rating} onRatingChange={setRating} />
        </Box>
        <Box mb={4}>
          <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_KEY} />
        </Box>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageChange}
          multiple
          title="Upload Images"
          style={{ display: 'none' }}
        />
        <Button
          bold
          size="medium"
          onClick={() => fileInputRef.current?.click()}
        >
          Upload Images
        </Button>

        <Box mt={3}>
          <Button
            bold
            type="submit"
            size="medium"
            disabled={loading || !content || !category}
          >
            {loading ? (
              <ImSpinner8 size={20} style={{ verticalAlign: 'middle' }} />
            ) : (
              'Submit Feedback'
            )}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default AddFeedback;

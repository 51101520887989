import { LoaderContainer, LoaderBar } from './style';

type LoaderProps = {
  isLoading: boolean;
};

const Loader: React.FC<LoaderProps> = ({ isLoading }) => (
  <LoaderContainer>
    <LoaderBar isLoading={isLoading} />
  </LoaderContainer>
);

export default Loader;

import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { getCookie } from '../../Utils/cookies';
import { notify } from '../../../storyBook/TostNotification';
import { registerPushSubscription } from '../../Utils/pushNotificationUtils';

const GoogleAuthHandler = () => {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const hasRunOnce = useRef(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tokenFromURL = urlParams.get('token');
    const _id = urlParams.get('userId');
    const email = urlParams.get('email');
    const firstName = urlParams.get('firstName');
    const lastName = urlParams.get('lastName');
    const phoneNumber = urlParams.get('phoneNumber');
    const masterPasswordSet = urlParams.get('masterPasswordSet') === 'true';

    const tokenFromCookie = getCookie('token');

    if (!hasRunOnce.current) {
      hasRunOnce.current = true;
    }

    if (tokenFromURL) {
      if (tokenFromURL !== tokenFromCookie) {
        // Save this token in the cookie
        localStorage.setItem('token', tokenFromURL);
        setIsAuthenticated(true);
        // Set the isAuthenticated flag in localStorage
        localStorage.setItem('isAuthenticated', 'true');
        // Save user details to localStorage
        const userDetails = {
          _id,
          userId: _id,
          email,
          firstName,
          lastName,
          phoneNumber,
          masterPasswordSet,
        };

        localStorage.setItem('userDetails', JSON.stringify(userDetails));

        // Show successful login toast notification
        notify({
          message: 'Successfully logged in with Google!',
          type: 'success',
        });

        // Navigate to the dashboard
        history.push('/dashboard');
      }

      // Register push subscription
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          registerPushSubscription(registration, isAuthenticated);
        });
      }
      // If token from URL is the same as the one in cookie, do nothing.
    } else {
      // Show failed login toast notification only if there's no token in the cookie
      if (!tokenFromCookie) {
        notify({ message: 'Google login failed.', type: 'error' });
      }

      // Handle the error, e.g., redirecting to a login page
      history.push('/login');
    }
  }, [location, history, setIsAuthenticated]);

  return null;
};

export default GoogleAuthHandler;

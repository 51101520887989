import styled from 'styled-components';
import theme from '../../theme';

export const getStatusColor = (status: string) => {
  switch (status) {
    case 'Pending':
      return theme.colors.pending;
    case 'Done':
      return theme.colors.success;
    case 'Overdue':
      return theme.colors.primaryRed;
    case 'Up Coming':
      return theme.colors.supportingBlue;
    default:
      return theme.colors.secondary;
  }
};

export const ChipContainer = styled.span<{ status: string; active: boolean }>`
  display: inline-block;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 12px;
  background-color: ${({ active }) =>
    active ? theme.colors.supportingBlue : theme.colors.light};
  color: ${({ active, status }) =>
    active ? theme.colors.white : getStatusColor(status)};
  margin: 2px;
  cursor: pointer;
  font-weight: 600;

  border: ${({ active }) =>
    active ? `2px solid ${theme.colors.supportingBlue}` : 'none'};
`;

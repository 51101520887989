import React, { useState } from 'react';
import { Timeline } from '../../types';
import { Box, Chip, Text, RotatableIcon } from '../../../storyBook';
import theme from '../../../theme';
import {
  parseReminderTime,
  formatTimeLeft,
  formatDate,
} from '../../Utils/helper';
import {
  CardWrapper,
  Category,
  StatusIndicator,
  InfoRow,
  Label,
  Value,
  Countdown,
  RotableContainer,
} from './style';

import SubMenu from '../../Timeline/SubMenu';
import DeleteTimelineModal from '../../Timeline/DeleteTimelineModal';

const TimelineCard = ({ timeline }: { timeline: Timeline }) => {
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedTimelineToDelete, setSelectedTimelineToDelete] = useState<
    string | null
  >(null);

  const handleDeleteClick = (timelineId: string) => {
    setSelectedTimelineToDelete(timelineId);
    setIsDeleteModalOpen(true);
  };

  const calculateHoursLeftForReminder = (reminderTime: string) => {
    if (!reminderTime) {
      return { full: '', concise: '' };
    }

    const now = new Date();
    const parsedTime = parseReminderTime(reminderTime);

    if (!parsedTime) {
      return { full: '', concise: '' };
    }

    const reminderDate = new Date(now);
    reminderDate.setHours(parsedTime.hour, parsedTime.minute, 0, 0);

    const diffMs = reminderDate.getTime() - now.getTime();
    if (diffMs < 0) {
      return { full: 'Time passed', concise: '' };
    }

    const totalHoursLeft = diffMs / (1000 * 60 * 60);
    const wholeHours = Math.floor(totalHoursLeft);
    const minutes = Math.round((totalHoursLeft - wholeHours) * 60);

    const fullString = `${wholeHours} hours and ${minutes} minutes left`;
    const conciseString = formatTimeLeft(wholeHours, minutes);

    return { full: fullString, concise: conciseString };
  };

  // Usage in the component
  const timeLeft = calculateHoursLeftForReminder(timeline.reminderTime ?? '');

  const handleMenuToggle = (id: string | null) => {
    setOpenMenuId(id);
  };
  return (
    <CardWrapper>
      <Category>{timeline.category}</Category>
      <Box mb={2}>
        {timeLeft.concise ? (
          <Chip label="Upcoming" status="Up Coming" />
        ) : (
          <Chip label="Overdue" status="Overdue" />
        )}
      </Box>

      <StatusIndicator>{timeLeft.concise}</StatusIndicator>
      <InfoRow>
        <Label as="span">Category</Label>
        <Value as="span">{timeline.category}</Value>
      </InfoRow>
      <InfoRow>
        <Label as="span">Date</Label>
        <Value as="span">
          {formatDate(new Date(timeline.reminderDate ?? ''))}
        </Value>
      </InfoRow>
      <InfoRow>
        <Label as="span">Time</Label>
        <Value as="span">{timeline.reminderTime}</Value>
      </InfoRow>
      <InfoRow>
        <Label as="span">Status</Label>
        <Chip label={timeline.status} status={timeline.status} />
      </InfoRow>
      <InfoRow>
        <Text bold color={theme.colors.veryDark}>
          {timeline.content}
        </Text>
      </InfoRow>
      <Countdown hasPassed={timeLeft.full === 'Time passed'}>
        {timeLeft.full}
      </Countdown>
      <RotableContainer>
        <RotatableIcon
          _id={timeline._id}
          onToggle={handleMenuToggle}
          isOpen={openMenuId === timeline._id}
        />

        {openMenuId === timeline._id && (
          <SubMenu
            id={timeline._id}
            showEditOption={false}
            showStatusOption
            onDelete={handleDeleteClick}
            setOpenMenuId={setOpenMenuId}
            status={timeline.status || 'Pending'}
          />
        )}
      </RotableContainer>
      <DeleteTimelineModal
        setOpenMenuId={setOpenMenuId}
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        selectedTimelineToDelete={selectedTimelineToDelete}
      />
    </CardWrapper>
  );
};

export default TimelineCard;

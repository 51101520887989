import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  HeaderContainer,
  FlexContainer,
  SearchFlexContainer,
  UserAvatar,
  TextInput,
  InputWrapper,
  SearchIcon,
  FilterAvatarButton,
  FilterCard,
  CategoryLink,
} from './style';
import { HeaderProps } from './types';
import { Flex, Text, Box, useIsMedia } from '../../storyBook';
import theme from '../../theme';
import { IoFilter } from 'react-icons/io5';
import { FaShoppingBasket } from 'react-icons/fa';
import { FaUserDoctor } from 'react-icons/fa6';
import { HiOutlineOfficeBuilding as OfficeIcon } from 'react-icons/hi';
import { TbSchool as SchoolIcon } from 'react-icons/tb';
import { useClickAway } from 'react-use';
import { RxCross2 } from 'react-icons/rx';
import { useSelectedCategory } from '../hooks/useSelected';
import { useSearch } from '../hooks/useSearch';
import { MdOutlineNotificationsNone } from 'react-icons/md';
import NotificationBadge from '../Navigation/NotificationBadge';
import useNotifications from '../hooks/useNotifications';
import useCategory from '../hooks/useCategory';
import { MdLabelOutline } from 'react-icons/md';

// Type for the category icons mapping
type CategoryIcons = {
  [key: string]: JSX.Element;
};

const Header: React.FC<HeaderProps> = () => {
  const { setSelectedCategory } = useSelectedCategory();
  const { searchTerm, setSearchTerm, activeSearchArea } = useSearch();
  const { isMobile, isTablet } = useIsMedia();
  const { notifications } = useNotifications();
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const isDashboardPath =
    currentPath === '/dashboard' ||
    currentPath === '/timeline' ||
    currentPath === '/snapnotes' ||
    currentPath === '/password';

  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
  const userInitial = userDetails?.firstName ? userDetails.firstName[0] : 'G';
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const filterCardRef = useRef<HTMLDivElement>(null);
  const { categories, fetchCategories } = useCategory();
  const defaultIcon = <MdLabelOutline size="20" />;

  const staticCategoryIcons: CategoryIcons = {
    Shopping: <FaShoppingBasket size="20" />,
    Doctor: <FaUserDoctor size="20" />,
    Office: <OfficeIcon size="20" />,
    School: <SchoolIcon size="20" />,
  };
  const categoryIcons: CategoryIcons = categories.reduce(
    (acc: CategoryIcons, cat) => {
      acc[cat.name] = staticCategoryIcons[cat.name] || defaultIcon;
      return acc;
    },
    {}
  );

  useEffect(() => {
    fetchCategories();
  }, []);

  // Determine if the search bar should be shown
  const showSearchBar =
    (currentPath === '/dashboard' && activeSearchArea === 'dashboard') ||
    (currentPath === '/snapnotes' && activeSearchArea === 'snapnotes') ||
    (currentPath === '/password' && activeSearchArea === 'password') ||
    (currentPath === '/timeline' && activeSearchArea === 'timeline');

  // Now determine the placeholder text inside the component
  let placeholderText = 'Jump to or search...';
  if (currentPath === '/dashboard') {
    placeholderText = 'Jump to or search todays timeline...';
  } else if (currentPath === '/timeline') {
    placeholderText = 'Jump to or search timelines...';
  } else if (currentPath === '/snapnotes') {
    placeholderText = 'Jump to or search snapnotes...';
  } else if (currentPath === '/password') {
    placeholderText = 'Jump to or search passwords...';
  }

  useEffect(() => {
    setSearchTerm('');
  }, [currentPath]);

  const navigateToProfile = () => {
    history.push('/profile');
  };

  const toggleFilterCard = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useClickAway(filterCardRef, () => {
    if (isFilterVisible) setIsFilterVisible(false);
  });

  // Calculate the count of unread notifications
  const unreadNotificationsCount = notifications.filter(
    (notification) => !notification.read
  ).length;

  return (
    <>
      <HeaderContainer isSticky={!isDashboardPath}>
        <FlexContainer
          justifyContent="space-between"
          alignItems="center"
          color="white"
          mx={[3, 3, 3, 5]}
          py={2}
          isSticky={!isDashboardPath}
        >
          <Box onClick={() => history.push('/')}>
            <Text as="h2" mb={2} color={theme.colors.supportingBlueLight}>
              livediary
            </Text>
          </Box>

          <Flex alignItems="center">
            <Box pr={theme.spacing.xs} pt={[1, 1, 1, 2]}>
              <NotificationBadge
                icon={
                  <MdOutlineNotificationsNone
                    size={isMobile ? '22px' : isTablet ? '25px' : '20px'}
                    style={{
                      color: theme.colors.mediumDark,
                      cursor: 'pointer',
                    }}
                    onClick={() => history.push('/notifications')}
                  />
                }
                count={unreadNotificationsCount}
              />
            </Box>
            <Box mr={[0, 0, 0, 3]}>
              <UserAvatar
                onClick={navigateToProfile}
                isClickable={!!navigateToProfile}
              >
                {userInitial}
              </UserAvatar>
            </Box>
          </Flex>
        </FlexContainer>
      </HeaderContainer>
      {isDashboardPath && showSearchBar && (
        <SearchFlexContainer justifyContent="space-between" alignItems="center">
          <InputWrapper>
            <TextInput
              type="search"
              placeholder={placeholderText}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <SearchIcon size={20} />
          </InputWrapper>
          {currentPath === '/timeline' && showSearchBar && (
            <FilterAvatarButton onClick={toggleFilterCard}>
              <IoFilter size={25} />
            </FilterAvatarButton>
          )}
        </SearchFlexContainer>
      )}
      {isFilterVisible && (
        <FilterCard ref={filterCardRef}>
          <RxCross2
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              cursor: 'pointer',
            }}
            size={20}
            onClick={() => setIsFilterVisible(false)}
          />

          {Object.entries(categoryIcons).map(([category, IconComponent]) => (
            <CategoryLink
              key={category}
              onClick={() => {
                setSelectedCategory(category);
                setIsFilterVisible(false);
              }}
            >
              <span className="icon">{IconComponent}</span> {category}
            </CategoryLink>
          ))}
        </FilterCard>
      )}
    </>
  );
};

export default Header;

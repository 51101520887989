import { useState, useEffect } from 'react';

import isClient from '../../utils/isClient';

type ViewportSize = {
  width: number;
};

const useWindowSize = (clientOnly = false): ViewportSize => {
  const [windowSize, setWindowSize] = useState<ViewportSize>({
    width: isClient && clientOnly ? window.innerWidth : 0,
  });

  useEffect(() => {
    function handleSize(): void {
      setWindowSize({
        width: window.innerWidth,
      });
    }

    if (!clientOnly) {
      handleSize();
    }

    window.addEventListener('resize', handleSize);

    return (): void => {
      window.removeEventListener('resize', handleSize);
    };
  }, [clientOnly]);

  return windowSize;
};

export default useWindowSize;

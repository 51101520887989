import styled from 'styled-components';
import { Box } from '../../storyBook';

interface NotificationCardProps {
  isPrevious: boolean;
}
export const NotificationContainer = styled(Box)`
  padding: 20px;
  font-family: 'Inter', sans-serif;
  background-color: ${({ theme }) => theme.colors.ultraLight};
  min-height: 100vh;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column-reverse;
`;

export const NotificationCard = styled(Box)<NotificationCardProps>`
  background-color: ${({ theme, isPrevious }) =>
    isPrevious ? theme.colors.ultraLight : theme.colors.white};
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;

  &:hover {
    transform: scale(1.05);
  }
`;

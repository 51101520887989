import React, { useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { Modal, TextInput, Button, Text } from '../../../../storyBook';
import { Box } from '@mui/material';
import theme from '../../../../theme';
import { Hr } from '../../../_common/style';
import { notify } from '../../../../storyBook/TostNotification';

type SetMasterPasswordProps = {
  setMasterPasswordModal: (setMasterPasswordModal: boolean) => void;
};
const SetMasterPassword = ({
  setMasterPasswordModal,
}: SetMasterPasswordProps) => {
  const [masterPasswords, setMasterPasswords] = useState('');

  const { setMasterPassword } = useAuth();
  const history = useHistory();
  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');

  const userId = userDetails?._id;
  const disabled = !masterPasswords;

  const handleSubmit = async () => {
    try {
      if (userId) {
        await setMasterPassword(userId, masterPasswords);
        userDetails.masterPasswordSet = true;
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        notify({
          message: 'Master Password Set Successfully',
          type: 'success',
        });
      }
    } catch (err: unknown) {
      const errorMsg = typeof err === 'string' ? err : 'An error occurred';
      notify({ message: errorMsg, type: 'error' });
    }

    setMasterPasswordModal(false);
    history.push('/password');
  };

  const handleClose = () => {
    setMasterPasswordModal(false);
  };

  return (
    <Modal isOpen={true} onClose={handleClose}>
      <Box p={4}>
        <Text as="h3" mb={2} color={theme.colors.mediumDark}>
          Set Master Password
        </Text>
        <Box mb={2}>
          <Hr />
        </Box>
        <Text mb={3}>Set Master Password to keep your passwords safe.</Text>
        <TextInput
          type="password"
          placeholder="Master Password"
          value={masterPasswords}
          onChange={(e) => setMasterPasswords(e.target.value)}
        />
        <Button onClick={handleSubmit} size="medium" bold disabled={disabled}>
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default SetMasterPassword;

import { toast, ToastOptions, ToastContainer } from 'react-toastify';

type NotifyProps = {
  message: string;
  type: 'info' | 'success' | 'warning' | 'error';
  options?: ToastOptions;
};

const notify = ({ message, type, options }: NotifyProps) => {
  const defaultOptions: ToastOptions = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  const mergedOptions = { ...defaultOptions, ...options };

  switch (type) {
    case 'success':
      toast.success(message, mergedOptions);
      break;
    case 'error':
      toast.error(message, mergedOptions);
      break;
    case 'warning':
      toast.warn(message, mergedOptions);
      break;
    case 'info':
      toast.info(message, mergedOptions);
      break;
    default:
      toast(message, mergedOptions);
      break;
  }
};

export { notify, ToastContainer };

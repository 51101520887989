import React from 'react';
import { useLocation } from 'react-router-dom';
import { Badge, BadgeContainer } from './style';

interface NotificationBadgeProps {
  icon: React.ReactNode;
  count: number;
}

const NotificationBadge: React.FC<NotificationBadgeProps> = ({
  icon,
  count,
}) => {
  const location = useLocation(); // Get the current location
  const isNotificationsRoute = location.pathname === '/notifications';

  return (
    <BadgeContainer>
      {icon}
      {!isNotificationsRoute && count > 0 && <Badge>{count}</Badge>}{' '}
      {/* Conditional rendering */}
    </BadgeContainer>
  );
};

export default NotificationBadge;

import { ButtonWrapper, StyledButton, Icon } from './style';

interface FloatingActionButtonProps {
  onClick: () => void;
  icon?: React.ReactNode;
}

const FloatingActionButton = ({
  onClick,
  icon = <Icon />,
}: FloatingActionButtonProps) => {
  return (
    <ButtonWrapper>
      <StyledButton onClick={onClick}>{icon}</StyledButton>
    </ButtonWrapper>
  );
};

export default FloatingActionButton;

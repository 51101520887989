import React, { createContext, useState, useEffect, useRef } from 'react';
import apiCaller, { ApiError } from '../../Utils/apiCaller';
import { notify } from '../../../storyBook/TostNotification';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';
import {
  LoginProps,
  LoginResponse,
  AuthProviderProps,
  AuthContextType,
} from './types';

const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const localAuth = localStorage.getItem('isAuthenticated');
    const token = localStorage.getItem('token');
    return localAuth === 'true' && token !== null;
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<LoginResponse | null>(null);
  const failedAttempts = useRef(0);

  const apiBaseUrl = getApiBaseUrl();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsAuthenticated(false);
        setLoading(false);
        return;
      }

      try {
        // Attempt to verify the token's validity with the backend
        const response = await apiCaller<{ isAuthenticated: boolean }>({
          endpoint: `${apiBaseUrl}/auth/auth-check`,
          method: 'GET',
          includeToken: true,
        });

        setIsAuthenticated(response.isAuthenticated);
        if (!response.isAuthenticated) {
          // Optionally clear local storage if the auth check fails
          localStorage.removeItem('isAuthenticated');
          localStorage.removeItem('token');
        }
      } catch (err) {
        setIsAuthenticated(false);
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('token');
        if (err instanceof Error) {
          setError('Failed to verify authentication status');
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const login = async (credentials: LoginProps) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<LoginResponse>({
        endpoint: `${apiBaseUrl}/auth/login`,
        method: 'POST',
        body: credentials,
      });
      if (response && response.status === 'Login successful') {
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('token', response.token); // Store the token
        localStorage.setItem('userDetails', JSON.stringify(response.user));
        notify({ message: response.status, type: 'success' });
      }
      setData(response);
    } catch (err) {
      setIsAuthenticated(false);
      if (err instanceof ApiError) {
        console.log('err', err);
        setError(err.message);
        notify({ message: err.message, type: 'error' });
      } else {
        setError('Unknown error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      const response = await apiCaller<{ status: string }>({
        endpoint: `${apiBaseUrl}/auth/logout`,
        method: 'POST',
      });
      if (response && response.status === 'Logout successful') {
        setIsAuthenticated(false);
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('token'); // Remove the token
        localStorage.removeItem('userDetails');
        localStorage.removeItem('token');
        document.cookie =
          'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=https://livediary.co.uk;';

        notify({ message: response.status, type: 'success' });
      }
      setData(null);
    } catch (err) {
      if (err instanceof ApiError) {
        setError(err.message);
        notify({ message: err.message, type: 'error' });
      } else {
        setError('Unknown error occurred');
      }
    }
  };

  const forgotPassword = async (email: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<{ status: string }>({
        endpoint: `${apiBaseUrl}/auth/forgot-password`,
        method: 'POST',
        body: { email },
        includeToken: true,
      });
      if (response && response.status) {
        notify({ message: response.status, type: 'success' });
      }
    } catch (err) {
      if (err instanceof ApiError) {
        setError(err.message);
        notify({ message: err.message, type: 'error' });
      } else {
        setError('Unknown error occurred while sending reset link');
      }
    } finally {
      setLoading(false);
    }
  };

  const masterPassword = async (userId: string, masterPassword: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<{ status: string; error?: string }>({
        endpoint: `${apiBaseUrl}/auth/master-password`,
        method: 'POST',
        body: { userId, masterPassword },
        includeToken: true,
      });

      // Check if the response indicates a failed validation
      if (response.error) {
        throw new Error(response.error); // Throw an error if validation fails
      }

      // Reset failed attempts on successful login
      failedAttempts.current = 0;
      notify({ message: response.status, type: 'success' });
    } catch (err) {
      if (err instanceof ApiError) {
        failedAttempts.current += 1; // Increment failed attempts on error

        if (failedAttempts.current === 2) {
          // Notify the user that they have one more attempt left
          notify({
            message:
              'Warning: You have one more attempt before you are logged out.',
            type: 'warning',
          });
        }

        if (failedAttempts.current >= 3) {
          // Log out after 3 failed attempts
          failedAttempts.current = 0; // Reset failed attempts after logout
          logout();
          notify({
            message: 'Too many failed attempts, logging out...',
            type: 'error',
          });
        } else {
          setError(err.message);
          notify({ message: err.message, type: 'error' });
          throw err; // Rethrow the error to be caught by handleValidate
        }
      } else {
        const unknownError =
          'Unknown error occurred while validating master password';
        setError(unknownError);
        notify({ message: unknownError, type: 'error' });
        throw new Error(unknownError); // Throw a new error
      }
    } finally {
      setLoading(false);
    }
  };

  const setMasterPassword = async (userId: string, masterPassword: string) => {
    setLoading(true);
    setError(null);
    try {
      await apiCaller<{ status: string }>({
        endpoint: `${apiBaseUrl}/auth/set-master-password`,
        method: 'POST',
        body: { userId, masterPassword },
        includeToken: true,
      });
    } catch (err) {
      if (err instanceof ApiError) {
        setError(err.message);
        notify({ message: err.message, type: 'error' });
      } else {
        setError('Unknown error occurred while setting master password');
      }
    } finally {
      setLoading(false);
    }
  };

  const forgotMasterPassword = async (email: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<{ status: string }>({
        endpoint: `${apiBaseUrl}/auth/forgot-master-password`,
        method: 'POST',
        body: { email },
        includeToken: true,
      });
      if (response && response.status) {
        notify({ message: response.status, type: 'success' });
      }
    } catch (err) {
      if (err instanceof ApiError) {
        setError(err.message);
        notify({ message: err.message, type: 'error' });
      } else {
        setError('Unknown error occurred while sending reset link');
      }
    } finally {
      setLoading(false);
    }
  };

  const resetMasterPassword = async (
    token: string,
    newMasterPassword: string
  ) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<{ status: string }>({
        endpoint: `${apiBaseUrl}/auth/reset-master-password`,
        method: 'POST',
        body: { token, newMasterPassword },
        includeToken: true,
      });
      if (response && response.status) {
        notify({ message: response.status, type: 'success' });
      }
    } catch (err) {
      if (err instanceof ApiError) {
        setError(err.message);
        notify({ message: err.message, type: 'error' });
      } else {
        setError('Unknown error occurred while resetting master password');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        login,
        logout,
        masterPassword,
        setMasterPassword,
        forgotMasterPassword,
        resetMasterPassword,
        forgotPassword,
        loading,
        error,
        data,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };

import { useState, useRef, useEffect } from 'react';
import { InputProps } from './types';
import {
  Container,
  Input,
  Label,
  ClearIcon,
  TogglePasswordIcon,
  SuccessMessage,
  Asterisk,
} from './style';
import { RxCross2 } from 'react-icons/rx';
import theme from '../../theme';
import { FiCheckCircle, FiEye, FiEyeOff } from 'react-icons/fi';
import { MdContentCopy } from 'react-icons/md';
import { ErrorMessage } from '../../component/_common/style';

const TextInput: React.FC<InputProps> = ({
  type = 'text',
  isCopy = false,
  value: propValue,
  onChange: propOnChange,
  name,
  placeholder,
  onFocus,
  autoFocus,
  background,
  validation,
  onValidation,
  required,
  id,
  ...rest
}) => {
  const [localValue, setLocalValue] = useState(propValue || '');
  const [isFocused, setIsFocused] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [hasBlurredAfterAutoFocus, setHasBlurredAfterAutoFocus] =
    useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const validateValue = (value: string) => {
    if (validation) {
      const error = validation(value);
      setValidationError(error);
      if (onValidation) {
        onValidation(!error);
      }
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
    if (propOnChange) propOnChange(e);
    validateValue(e.target.value);
  };

  const togglePasswordVisibility = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsPasswordVisible((prev) => !prev);
  };

  const handleFocus = () => {
    setIsFocused(true);
    onFocus && onFocus();
  };
  const handleBlur = () => {
    setIsFocused(false);
    if (autoFocus && !hasBlurredAfterAutoFocus) {
      setHasBlurredAfterAutoFocus(true);
      return; // Exit early to avoid validation on initial blur after autoFocus.
    }
    validateValue(localValue);
  };

  const handleClickLabel = () => {
    inputRef.current?.focus();
  };

  const handleClear = () => {
    setLocalValue('');
    setValidationError(null);
    if (propOnChange) {
      const event: React.ChangeEvent<HTMLInputElement> = {
        target: { value: '', name },
      } as React.ChangeEvent<HTMLInputElement>;
      propOnChange(event);
    }
  };

  // Function to copy text to clipboard
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(localValue);
      setIsCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  // Reset copy feedback after some time
  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 2000); // Reset after 2 seconds
      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  return (
    <>
      <Container>
        <Label
          htmlFor={id}
          isFocused={isFocused || Boolean(localValue)}
          onClick={handleClickLabel}
          background={background}
        >
          {placeholder}
          {required && <Asterisk>*</Asterisk>}
        </Label>
        <Input
          type={isPasswordVisible ? 'text' : type}
          name={name}
          id={id}
          value={localValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoFocus={autoFocus}
          background={background}
          ref={inputRef}
          {...rest}
        />

        {localValue && (
          <ClearIcon onClick={handleClear}>
            {validationError ? (
              <RxCross2 size={20} color={theme.colors.primaryRed} />
            ) : (
              onValidation && (
                <FiCheckCircle size={20} color={theme.colors.primaryGreen} />
              )
            )}
          </ClearIcon>
        )}
        {isCopy && (
          <ClearIcon onClick={handleCopy}>
            <MdContentCopy size={20} color={theme.colors.offBlack} />
          </ClearIcon>
        )}

        {type === 'password' && (
          <TogglePasswordIcon onClick={togglePasswordVisibility}>
            {isPasswordVisible ? <FiEyeOff size={18} /> : <FiEye size={18} />}
          </TogglePasswordIcon>
        )}
        {/* Error and Success Message Handling */}
      </Container>
      {validationError && <ErrorMessage>{validationError}</ErrorMessage>}
      {isCopied && <SuccessMessage>Copied to clipboard!</SuccessMessage>}
    </>
  );
};

export default TextInput;

// SearchContext.tsx
import React, {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

export interface SearchContextType {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  activeSearchArea: string;
  setActiveSearchArea: Dispatch<SetStateAction<string>>;
}

const defaultState = {
  searchTerm: '',
  activeSearchArea: '',
  setSearchTerm: () => {},
  setActiveSearchArea: () => {},
};

export const SearchContext = createContext<SearchContextType>(defaultState);

export const SearchProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activeSearchArea, setActiveSearchArea] = useState<string>('');

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        activeSearchArea,
        setSearchTerm,
        setActiveSearchArea,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

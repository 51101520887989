import type { ReactNode } from 'react';
import { Button } from './style';

interface IconButtonProps {
  children: ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isAutoBackground?: boolean;
  title?: string;
  'aria-label'?: string;
}

const IconButton = ({
  children,
  onClick,
  isDisabled = false,
  isFullWidth = false,
  title,
  'aria-label': ariaLabel,
  isAutoBackground = false,
}: IconButtonProps) => (
  <Button
    aria-label={ariaLabel}
    isFullWidth={isFullWidth}
    isAutoBackground={isAutoBackground}
    disabled={isDisabled}
    onClick={onClick}
    role="button"
    title={title}
    type="button"
  >
    {children}
  </Button>
);

export default IconButton;

// ImageCrop.tsx
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ImageZoom from '../ImageZoom/index';
import { useState } from 'react';

interface ImageCropProps {
  src: string;
  crop: Crop;
  onCropChange: (crop: Crop) => void;
  zoom: number;
  setZoom: (zoom: number | ((prevZoom: number) => number)) => void;
  isPinching: boolean;
  setIsPinching: (isPinching: boolean) => void;
  onCropComplete: (crop: Crop) => void;
  imageRef: React.RefObject<HTMLImageElement>;
  isCropMode: boolean;
  showPreviewImage?: boolean;
  isPanning: boolean;
  setIsPanning: (panning: boolean) => void;
  translateX: number;
  translateY: number;
  setTranslateX: (translateX: number) => void;
  setTranslateY: (translateY: number) => void;
}

const ImageCrop: React.FC<ImageCropProps> = ({
  src,
  crop,
  onCropChange,
  showPreviewImage,
  zoom,
  setZoom,
  isCropMode,
  isPinching,
  setIsPinching,
  onCropComplete,
  isPanning,
  setIsPanning,
  imageRef,
  translateX,
  translateY,
  setTranslateX,
  setTranslateY,
}) => {
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);

  const handleMouseDown = (e: React.MouseEvent<HTMLImageElement>) => {
    setIsPanning(true);
    setStartX(e.clientX - translateX);
    setStartY(e.clientY - translateY);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLImageElement>) => {
    if (!isPanning) return;

    const newX = e.clientX - startX;
    const newY = e.clientY - startY;

    setTranslateX(newX);
    setTranslateY(newY);
  };

  const handleMouseUp = () => {
    setIsPanning(false);
  };

  // Touch equivalent for mobile
  const handleTouchStart = (e: React.TouchEvent<HTMLImageElement>) => {
    setIsPanning(true);
    setStartX(e.touches[0].clientX - translateX);
    setStartY(e.touches[0].clientY - translateY);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLImageElement>) => {
    if (!isPanning) return;

    const newX = e.touches[0].clientX - startX;
    const newY = e.touches[0].clientY - startY;

    setTranslateX(newX);
    setTranslateY(newY);
  };

  const handleTouchEnd = () => {
    setIsPanning(false);
  };

  const img = (
    <img
      ref={imageRef}
      src={src}
      alt="Preview"
      style={{
        transform: `scale(${zoom}) translate(${translateX}px, ${translateY}px)`,
        transformOrigin: 'top left',
        cursor: isPanning ? 'grab' : 'default',
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    />
  );

  return (
    <ImageZoom
      zoom={zoom}
      setZoom={setZoom}
      isPinching={isPinching}
      setIsPinching={setIsPinching}
    >
      <div
        style={{
          position: 'relative',
          width: 'fit-content',
          margin: '0 auto',
          overflow: 'hidden',
        }}
      >
        {showPreviewImage && !isCropMode && img}
        {isCropMode && (
          <ReactCrop
            crop={crop}
            onChange={onCropChange}
            onComplete={onCropComplete}
            keepSelection={true}
            disabled={isPinching}
            style={{
              display: 'block',
              margin: '0 auto',
            }}
          >
            {img}
          </ReactCrop>
        )}
      </div>
    </ImageZoom>
  );
};

export default ImageCrop;

import { NavigationItem, DesktopNavContainer } from './styles';
import { LuChevronRight } from 'react-icons/lu';

type MenuItem = {
  title: string;
  icon: React.ReactNode;
  route: string;
};

type DesktopNavigationProps = {
  menu: MenuItem[];
};
const DesktopNavigation: React.FC<DesktopNavigationProps> = ({ menu }) => {
  return (
    <>
      <DesktopNavContainer>
        {menu.map((item, index) => (
          <NavigationItem key={index} to={item.route}>
            {item.icon}
            <span>{item.title}</span>
            <LuChevronRight size="16px" />
          </NavigationItem>
        ))}
      </DesktopNavContainer>
    </>
  );
};

export default DesktopNavigation;

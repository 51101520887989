import React, { createContext, useState, ReactNode } from 'react';

export interface SelectedCategoryContextType {
  selectedCategory: string | null;
  setSelectedCategory: (category: string | null) => void;
}

const defaultContextValue: SelectedCategoryContextType = {
  selectedCategory: null,
  setSelectedCategory: () => {}, // Empty function as a placeholder
};

export const SelectedCategoryContext =
  createContext<SelectedCategoryContextType>(defaultContextValue);

export const SelectedCategoryProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  return (
    <SelectedCategoryContext.Provider
      value={{ selectedCategory, setSelectedCategory }}
    >
      {children}
    </SelectedCategoryContext.Provider>
  );
};

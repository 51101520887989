import { Box, Text, Flex, Button } from '../../../storyBook';
import theme from '../../../theme';
import { LuChevronRight } from 'react-icons/lu';
import { Hr, StyledLink } from '../../_common/style';
import useAuth from '../../hooks/useAuth';
import { useRouteMatch, useHistory } from 'react-router-dom';

const MenuItem = ({
  text,
  to,
  isLast,
}: {
  text: string;
  to: string;
  isLast?: boolean;
}) => (
  <StyledLink to={to}>
    <Flex alignItems="center" justifyContent="space-between" mb={3}>
      <Text>{text}</Text>
      <LuChevronRight size={20} color={theme.colors.medium} />
    </Flex>
    <Box mb={3}>{!isLast && <Hr />}</Box>
  </StyledLink>
);

const Profile = () => {
  const { logout } = useAuth();
  const history = useHistory();
  const { path } = useRouteMatch();

  const fallbackUserDetails = JSON.parse(
    localStorage.getItem('userDetails') || '{}'
  );
  const firstName = fallbackUserDetails?.firstName;

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <>
      <Box mb={5}>
        <Text as="h1" color={theme.colors.offBlack} mb={5} mt={2}>
          Hello {firstName}
        </Text>
        <MenuItem text="Personal information" to={`${path}/details`} />
        <MenuItem text="Privacy Policy" to={`${path}/privacy-policy`} />
        <MenuItem text="Feedback" to={`${path}/feedback`} />
        <MenuItem text="Documents" to={`${path}/documents`} isLast />
      </Box>

      <Box width="100px">
        <Button
          styleType="secondaryOutline"
          size="medium"
          type="button"
          onClick={handleLogout}
          bold
        >
          Logout
        </Button>
      </Box>
    </>
  );
};

export default Profile;

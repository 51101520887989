import styled from 'styled-components';
import { Button } from '../../../../storyBook';
import { PiLockKey } from 'react-icons/pi';

export const StyleButton = styled(Button)`
  color: ${({ theme }) => theme.colors.supportingBlue};
`;

export const StylePiLockKey = styled(PiLockKey)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.supportingBlueLight};
  color: ${({ theme }) => theme.colors.white};
  padding: 10px;
`;

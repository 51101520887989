import {
  Box,
  Button,
  Flex,
  Text,
  ColorPicker,
  IconButton,
} from '../../../../storyBook';
import { FiCrop } from 'react-icons/fi';
import theme from '../../../../theme';
import { CenterText } from '../../../_common/style';
import { MdAutoFixHigh } from 'react-icons/md';

interface ControlPanelProps {
  setIsEditPanelOpen: (isEditPanelOpen: boolean) => void;
  setHideEditIcon: (hideEditIcon: boolean) => void;
  saveCroppedImage: () => void;
  resetToOriginal: () => void;
  setIsCropMode: (isCropMode: boolean) => void;
  isCropMode: boolean;
  cropSize?: string | number;
  setBackgroundColor?: (color: string) => void;
  applyBackgroundChange?: () => void;
  backgroundColor: string;
  setHasBackgroundChanged?: (hasBackgroundChanged: boolean) => void;
  hasBackgroundChanged?: boolean;
  setIsHideAutoMask?: (isHideAutoMask: boolean) => void;
  isHideAutoMask?: boolean;
}

const ControlPanel = ({
  setIsEditPanelOpen,
  setBackgroundColor,
  applyBackgroundChange,
  setHasBackgroundChanged,
  hasBackgroundChanged,
  setIsHideAutoMask,
  isHideAutoMask,
  setHideEditIcon,
  saveCroppedImage,
  resetToOriginal,
  setIsCropMode,
  backgroundColor,
  isCropMode,
  cropSize,
}: ControlPanelProps) => {
  const handleCancelButton = () => {
    setIsEditPanelOpen(false);
    setHideEditIcon(true);
    setIsCropMode(false);
  };

  const handleCropClick = () => {
    setIsCropMode(true);
  };

  const handleBackgroundChange = () => {
    // Apply the background change
    applyBackgroundChange && applyBackgroundChange();
  };

  return (
    <>
      <Box>
        <CenterText>
          <Text as="small" bold color={theme.colors.medium}>
            {cropSize && `Crop size: ${cropSize}`}
          </Text>
        </CenterText>
      </Box>
      <Flex
        flexDirection="column"
        padding="10px"
        backgroundColor={theme.colors.ultraLight}
      >
        <Box>
          <Flex justifyContent="space-around">
            <Box style={{ cursor: 'pointer' }}>
              <IconButton onClick={handleCropClick}>
                <FiCrop size="18px" />
                <Text as="small" bold>
                  Crop
                </Text>
              </IconButton>
            </Box>
            <Box>
              <ColorPicker
                label="Magic Backdrop"
                color={backgroundColor}
                onChange={(color) => {
                  setBackgroundColor && setBackgroundColor(color);
                  setHasBackgroundChanged && setHasBackgroundChanged(true);
                  applyBackgroundChange && applyBackgroundChange();
                  setIsHideAutoMask && setIsHideAutoMask(false);
                }}
                ariaLabel="Crop color"
              />
            </Box>

            {isHideAutoMask && (
              <IconButton onClick={handleBackgroundChange} isAutoBackground>
                <MdAutoFixHigh size="20px" />
                <Text as="small" bold>
                  Auto Mask
                </Text>
              </IconButton>
            )}
          </Flex>
        </Box>
        <Flex justifyContent="space-between">
          <Box width="100px">
            <Button
              styleType="secondaryOutline"
              size="small"
              bold
              onClick={handleCancelButton}
            >
              Cancel
            </Button>
          </Box>
          <Flex>
            {(isCropMode || hasBackgroundChanged) && (
              <Box width="100px" pr={2}>
                <Button
                  styleType="secondary"
                  size="small"
                  bold
                  onClick={resetToOriginal}
                >
                  Revert
                </Button>
              </Box>
            )}
            <Box width="100px">
              <Button
                styleType="primary"
                size="small"
                bold
                onClick={saveCroppedImage}
                disabled={!isCropMode && !hasBackgroundChanged}
              >
                Save
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default ControlPanel;

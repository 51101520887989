import { useContext, useEffect, useRef } from 'react';
import { NotificationContext } from '../../Context/NotificationsContext';
import RingAlarmDisplay from '../../pages/TimelineForm/RingAlarmDisplay';
import AudioSound from '../../../media/sounds/alarm.mp3';

const NotificationRing = () => {
  const {
    isRingNotificationActive,
    setIsRingNotificationActive,
    ringAlarmNotification,
  } = useContext(NotificationContext);
  const audio = useRef(new Audio(AudioSound));

  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    if (isRingNotificationActive) {
      audio.current.play();
      // Set a timer to automatically deactivate the ring after 40 seconds
      timerRef.current = window.setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        deactivateRing();
      }, 30000);
    } else {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
      audio.current.pause();
      audio.current.currentTime = 0;
    }
    return () => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isRingNotificationActive]);

  const deactivateRing = () => {
    setIsRingNotificationActive(false);
    if (audio.current) {
      audio.current.pause();
      audio.current.currentTime = 0; // Reset audio to start
    }
  };

  return (
    <>
      {isRingNotificationActive && ringAlarmNotification && (
        <RingAlarmDisplay
          onDeactivate={deactivateRing}
          notification={ringAlarmNotification}
        />
      )}
    </>
  );
};

export default NotificationRing;

import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import useWindowSize from '../useWindowSize';
import { Theme } from '../../../theme/types';

interface UseIsMediaReturn {
  isSmallPhone: boolean;
  isMobile: boolean;
  smallTablet: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isGiant: boolean;
  [key: string]: boolean;
}

const useIsMedia = (): UseIsMediaReturn => {
  const { width } = useWindowSize();
  const { breakpointNames } = useContext(ThemeContext) as Theme;

  // Assuming breakpointNames values are px values in string format.
  const toInt = (breakpoint: string) => parseInt(breakpoint, 10);

  // Dynamically generate boolean values for each breakpoint
  const mediaBools = Object.keys(breakpointNames).reduce((acc, cur) => {
    acc[cur] =
      width <= toInt(breakpointNames[cur as keyof typeof breakpointNames]);
    return acc;
  }, {} as Record<string, boolean>);

  return {
    ...mediaBools,
    isSmallPhone: width <= toInt(breakpointNames.smallPhone),
    isMobile: width <= toInt(breakpointNames.phone),
    smallTablet:
      width > toInt(breakpointNames.phone) &&
      width <= toInt(breakpointNames.smallTablet),
    isTablet:
      width > toInt(breakpointNames.phone) &&
      width <= toInt(breakpointNames.tablet),
    isDesktop:
      width > toInt(breakpointNames.tablet) &&
      width <= toInt(breakpointNames.desktop),
    isGiant: width > toInt(breakpointNames.desktop),
  };
};

export default useIsMedia;

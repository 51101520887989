import { useContext } from 'react';
import { CategoryContext } from '../../Context/CategoryContext';
import { CategoryContextType } from '../../Context/CategoryContext/types';

const useCategory = (): CategoryContextType => {
  const context = useContext(CategoryContext);

  if (context === null) {
    throw new Error('useCategory must be used within an CategoryProvider');
  }

  return {
    ...context,
  };
};

export default useCategory;

import styled from 'styled-components';
import Calendar from 'react-calendar';

export const StyledCalendar = styled(Calendar)`
  font-family: inherit;
  font-size: 1.2rem;
  border: none;
  width: 100%;
  max-width: 800px;
  height: auto;

  .react-calendar__month-view__days {
    /* Adjust the size of each day tile */
    .react-calendar__tile {
      padding: 1rem;
    }
  }
`;

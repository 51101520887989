import styled from 'styled-components';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { rem } from 'polished';

export const CustomDatePicker = styled(DatePicker)`
  .MuiInputBase-root {
    background-color: ${({ theme }): string => theme.colors.ultraLight};
  }
  .MuiInputBase-input {
    font-weight: 600;
  }
`;

export const CustomTimePicker = styled(TimePicker)`
  .MuiInputBase-root {
    background-color: ${({ theme }): string => theme.colors.ultraLight};
  }
  .MuiInputBase-input {
    font-weight: 600;
  }
`;

export const BoldLabel = styled.label`
  font-weight: 600;
`;

export const BoldLabelAsterisk = styled.label`
  color: ${({ theme }): string => theme.colors.primaryRed};
  font-size: ${rem(18)};
  font-weight: 400;
`;

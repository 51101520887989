import { useContext } from 'react';
import {
  SelectedCategoryContext,
  SelectedCategoryContextType,
} from '../../Context/SelectedCategoryContext';

export const useSelectedCategory = (): SelectedCategoryContextType => {
  const context = useContext(SelectedCategoryContext);

  if (context === null) {
    throw new Error(
      'useSelectedCategory must be used within a SelectedCategoryProvider'
    );
  }

  return {
    ...context,
  };
};

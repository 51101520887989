import { OptionsProps } from '../../../storyBook/Select/types';

export const stagesText = [
  {
    header: "What's your name?",
    subText: 'Enter your first and last name to create an account.',
  },
  {
    header: 'Enter your phone number and email',
    subText:
      'Enter the mobile number and email on which you want to be contacted.',
  },
  {
    header: 'Enter your password',
    subText: 'Enter the password you want to use for your account.',
  },
];

export const feedbackOptionsList: OptionsProps[] = [
  { value: 'General', label: 'General' },
  { value: 'Feature', label: 'Feature Request' },
  { value: 'Bug', label: 'Bug Report' },
  { value: 'Others', label: 'Others' },
];

import {
  Container,
  Title,
  LastUpdated,
  Section,
  Heading,
  List,
  ListItem,
  TextPara,
} from './style';
import { Card, Box, Flex, useIsMedia, Back } from '../storyBook';
import theme from '../theme';

export const PrivacyPolicy: React.FC = () => {
  const { isMobile } = useIsMedia();
  return (
    <>
      <Box mb={3} ml={3}>
        <Back arrow onClick={() => history.back()} />
      </Box>

      <Container>
        <Flex>
          <img
            src="/livediary-logo.png"
            alt="Live Diary Logo"
            width="50px"
            height="50px"
          />
          <Title as="h2">Privacy Policy</Title>
        </Flex>

        <LastUpdated mt={isMobile ? -3 : 0}>
          Last updated: 29 May 2024
        </LastUpdated>

        <Section>
          <Heading>Welcome</Heading>
          <TextPara>
            Thank you for choosing to be part of our community at{' '}
            <strong>Live Diary</strong>. We are committed to protecting your
            personal information and your right to privacy. If you have any
            questions or concerns about our policy, or our practices with
            regards to personal information.
          </TextPara>
        </Section>

        <Section>
          <Heading>1. What Information Do We Collect?</Heading>
          <Heading>1.1 Personal Information You Disclose to Us</Heading>
          <TextPara>
            We collect personal information that you provide to us such as name,
            address, contact information, passwords and security data, and
            payment information. This information is primarily needed to
            maintain the security and operation of our App, and for our internal
            analytics and reporting purposes.
          </TextPara>
          <Heading>1.2 Information Automatically Collected</Heading>
          <TextPara>
            We automatically collect certain information when you visit, use or
            navigate the App. This information includes device and usage
            information, such as your IP address, browser and device
            characteristics, operating system, language preferences, and more.
          </TextPara>
        </Section>

        <Section>
          <Heading>2. How Do We Use Your Information?</Heading>
          <Box ml={!isMobile ? 5 : 0}>
            <List>
              <ListItem>
                To facilitate account creation and login process.
              </ListItem>
              <ListItem>To send administrative information to you.</ListItem>
              <ListItem>To fulfill and manage your orders.</ListItem>
              <ListItem>
                To respond to user inquiries and offer support to users.
              </ListItem>
              <ListItem>
                To enhance the performance and functionality of our services but
                are non-essential to their use.
              </ListItem>
            </List>
          </Box>
        </Section>

        <Section>
          <Heading>3. Will Your Information Be Shared with Anyone?</Heading>
          <TextPara>
            We only share information with your consent, to comply with laws, to
            protect your rights, or to fulfill business obligations. We may also
            share your data with our affiliates, in which case we will require
            those affiliates to honor this privacy policy.
          </TextPara>
        </Section>

        <Section>
          <Heading>4. How Do We Handle Your Social Logins?</Heading>
          <TextPara>
            Our App offers you the ability to register and login using your
            third-party social media account details. The profile information we
            receive may vary depending on the social media provider concerned,
            but will generally include your name, email address, friends list,
            profile picture, and other information you choose to make public.
          </TextPara>
        </Section>

        <Section>
          <Heading>5. Data Retention</Heading>
          <TextPara>
            We will retain your personal information only for as long as is
            necessary for the purposes set out in this privacy policy. We will
            retain and use your information to the extent necessary to comply
            with our legal obligations, resolve disputes, and enforce our legal
            agreements and policies.
          </TextPara>
        </Section>

        <Section>
          <Heading>6. Your Data Protection Rights</Heading>
          <Box ml={!isMobile ? 5 : 0}>
            <List>
              <ListItem>
                You have the right to request access to the personal information
                we hold about you.
              </ListItem>
              <ListItem>
                You have the right to request that we correct any inaccuracies
                in that information.
              </ListItem>
              <ListItem>
                You have the right to request the deletion of your data under
                certain circumstances.
              </ListItem>
              <ListItem>
                You have the right to object to our processing of your data.
              </ListItem>
            </List>
          </Box>
        </Section>
        <Card background={theme.colors.light} py={3}>
          <Section>
            <Heading>Contact Us</Heading>
            <TextPara>
              If you have questions or comments about this policy, you may email
              us at{' '}
              <strong>
                <a href="mailto: live.diary23@gmail.com">
                  live.diary23@gmail.com
                </a>
              </strong>
            </TextPara>
          </Section>
        </Card>
      </Container>
    </>
  );
};

import { useState } from 'react';
import useCategory from '../../../hooks/useCategory';
import {
  Box,
  Text,
  Button,
  Flex,
  TextInput,
  Modal,
} from '../../../../storyBook';
import { Hr } from '../../../_common/style';

interface CategoryModalProps {
  onAddCategory: (name: string) => void;
  onDeleteCategory: (name: string) => void;
  setShowCategoryModal: (show: boolean) => void;
  modalType?: 'add' | 'delete';
  categoryName?: string;
}

const CategoryModal: React.FC<CategoryModalProps> = ({
  onAddCategory,
  onDeleteCategory,
  setShowCategoryModal,
  modalType = 'add', // 'add' or 'delete'
  categoryName = '', // Only needed for delete confirmation
}) => {
  const { addCategory, deleteCategory } = useCategory();
  const [newCategoryName, setNewCategoryName] = useState('');

  const handleAddCategory = async () => {
    await addCategory(newCategoryName);
    onAddCategory(newCategoryName);
    setNewCategoryName('');
    setShowCategoryModal(false);
  };

  const handleDeleteCategory = async () => {
    await deleteCategory(categoryName);
    onDeleteCategory(categoryName); // Refresh categories after deletion
    setShowCategoryModal(false);
  };

  const handleClose = () => {
    setShowCategoryModal(false);
  };

  return (
    <Modal onClose={handleClose} isOpen={true}>
      <Box p={3}>
        <Text as="h3" mb={3}>
          {modalType === 'add'
            ? 'Add a New Category to the list'
            : 'Confirm Deletion'}
        </Text>
        <Box mb={3}>
          <Hr />
        </Box>
        {modalType === 'add' ? (
          <>
            <TextInput
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Add new category"
            />
            <Flex>
              <Box width="100px" mr={4}>
                <Button
                  bold
                  styleType="primary"
                  size="small"
                  onClick={handleAddCategory}
                >
                  Save
                </Button>
              </Box>
              <Box width="100px">
                <Button
                  bold
                  styleType="secondary"
                  size="small"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Flex>
          </>
        ) : (
          <>
            <Text mb={3}>
              Are you sure you want to delete the category{' '}
              <Text as="span" bold>
                {categoryName}
              </Text>
              ?
            </Text>
            <Flex>
              <Box width="100px" mr={4}>
                <Button
                  bold
                  styleType="danger"
                  size="small"
                  onClick={handleDeleteCategory}
                >
                  Delete
                </Button>
              </Box>
              <Box width="100px">
                <Button
                  bold
                  styleType="secondary"
                  size="small"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Flex>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default CategoryModal;

import { Box, Flex, Text, Button } from '../../../../storyBook';
import { stagesText } from '../../../Utils/constants';
import { isFormValidForStage } from '../../../Utils/helper';
import { CenterText, StyledLink, Form } from '../../../_common/style';
import { StageOne, StageTwo, StageThree } from '../Stages';
import theme from '../../../../theme';
import { FormContentProps } from './types';

const FormContent: React.FC<FormContentProps> = ({
  stage,
  form,
  emailData,
  phoneData,
  emailValid,
  phoneValid,
  handleInputChange,
  handleNext,
  handleSubmit,
  setShowErrorMessage,
}) => {
  return (
    <Flex
      textAlign="center"
      flexDirection="column"
      mx={[0, 0, 6, 2]}
      py={[3, 3, 3, 4]}
    >
      <Form onSubmit={handleSubmit}>
        <Box>
          <Box mb={3}>
            <Text as="h3" mb={2} bold>
              {stagesText[stage - 1]?.header}
            </Text>
            <Text as="p" mb={4}>
              {stagesText[stage - 1]?.subText}
            </Text>
          </Box>

          {stage === 1 && (
            <StageOne
              form={form}
              handleInputChange={handleInputChange}
              setShowErrorMessage={setShowErrorMessage}
            />
          )}
          {stage === 2 && (
            <StageTwo
              form={form}
              emailData={emailData}
              phoneData={phoneData}
              handleInputChange={handleInputChange}
              setShowErrorMessage={setShowErrorMessage}
            />
          )}
          {stage === 3 && (
            <StageThree
              form={form}
              handleInputChange={handleInputChange}
              setShowErrorMessage={setShowErrorMessage}
            />
          )}

          <Box mb={4}>
            <Button
              bold
              type="button"
              styleType="primary"
              size="medium"
              disabled={
                !isFormValidForStage(form, stage) || !emailValid || !phoneValid
              }
              onClick={stage < 3 ? handleNext : handleSubmit}
            >
              {stage < 3 ? 'Next' : 'Submit'}
            </Button>
          </Box>
        </Box>
      </Form>

      <StyledLink to="/">
        <CenterText as="small" mb={2} color={theme.colors.medium}>
          Already have an account?
        </CenterText>
      </StyledLink>
    </Flex>
  );
};

export default FormContent;

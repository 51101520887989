// TimelinesCalendar.js

import 'react-calendar/dist/Calendar.css';
import { Box, Text } from '../../../storyBook';
import { Timeline } from '../../types';
import { StyledCalendar } from './style';
import theme from '../../../theme';

interface TimelinesCalendarProps {
  timelines: Timeline[];
  onDateChange: (date: Date) => void;
  selectedDate: Date;
}

const TimelinesCalendar = ({
  timelines,
  onDateChange,
  selectedDate,
}: TimelinesCalendarProps) => {
  const dateHasTimelines = (date: Date) => {
    return timelines.some(
      (timeline) =>
        timeline.reminderDate &&
        new Date(timeline.reminderDate).toDateString() === date.toDateString()
    );
  };

  const renderTileContent = ({ date, view }: { date: Date; view: string }) => {
    if (view === 'month' && dateHasTimelines(date)) {
      const timelinesForDate = timelines.filter(
        (timeline) =>
          timeline.reminderDate &&
          new Date(timeline.reminderDate).toDateString() === date.toDateString()
      );

      return (
        <Box>
          {timelinesForDate.map((tl) => (
            <Text
              key={tl._id}
              as="span"
              style={{
                fontSize:
                  timelinesForDate.length === 1 ||
                  timelinesForDate.length === 2 ||
                  timelinesForDate.length === 3
                    ? '22px'
                    : '16px',
                marginRight: '1px',
              }}
              color={theme.colors.primary}
            >
              •
            </Text>
          ))}
        </Box>
      );
    }
    return null;
  };

  return (
    <StyledCalendar
      onChange={(value) => onDateChange(value as Date)}
      value={selectedDate}
      tileContent={renderTileContent}
    />
  );
};

export default TimelinesCalendar;

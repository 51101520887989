/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useState } from 'react';
import apiCaller from '../../Utils/apiCaller';
import { handleError } from '../../Utils/helper';
import { notify } from '../../../storyBook/TostNotification';
// import { Feedback, FeedbackContextType, ChildrenProps } from './types';
// import useAuth from '../../hooks/useAuth';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';
import { ReactNode } from 'react';

export interface Feedback {
  id: string;
  userId: string;
  content: string;
  images: string[];
  category: string;
  rating: number;
}

export interface FeedbackContextType {
  feedbacks: Feedback[];
  addFeedback: (
    newFeedbackData: FormData,
    captchaToken: string
  ) => Promise<void>;
  getAllFeedback: () => Promise<void>;
  loading: boolean;
  error: string | null;
}

export interface ChildrenProps {
  children: ReactNode;
}

export const FeedbackContext = createContext<FeedbackContextType | null>(null);

export const FeedbackProvider = ({ children }: ChildrenProps) => {
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  // const { isAuthenticated } = useAuth();
  const apiBaseUrl = getApiBaseUrl();

  const getAllFeedback = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<Feedback[]>({
        endpoint: `${apiBaseUrl}/api/feedback`,
        method: 'GET',
        includeToken: true,
      });
      setFeedbacks(response);
    } catch (err) {
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };
  const verifyCaptcha = async (token: string, action: string) => {
    try {
      const response = await apiCaller({
        endpoint: `${apiBaseUrl}/api/feedback/verify-captcha`,
        method: 'POST',
        body: JSON.stringify({ token, action }),
        includeToken: true,
      });
      return response;
    } catch (error) {
      return { success: false, message: 'Failed to verify CAPTCHA', error };
    }
  };

  const addFeedback = async (
    newFeedbackData: FormData,
    captchaToken: string
  ) => {
    setLoading(true);
    setError(null);

    // First verify the CAPTCHA
    const captchaResult = await verifyCaptcha(captchaToken, 'add_feedback');
    if (!(captchaResult as any).success) {
      notify({
        message:
          (captchaResult as any).message || 'CAPTCHA verification failed',
        type: 'error',
      });
      setLoading(false);
      return;
    }

    // Proceed with submitting the feedback
    try {
      await apiCaller({
        endpoint: `${apiBaseUrl}/api/feedback`,
        method: 'POST',
        body: newFeedbackData,
        includeToken: true,
      });
      notify({ message: 'Feedback submitted successfully!', type: 'success' });
      setLoading(false);
    } catch (err) {
      notify({ message: error || 'An error occurred', type: 'error' });
      handleError(err, setError);
      setLoading(false);
    }
  };

  return (
    <FeedbackContext.Provider
      value={{
        feedbacks,
        addFeedback,
        getAllFeedback,
        loading,
        error,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

import { Modal, Box, Text, Button, Flex } from '../../../storyBook';
import { Hr } from '../../_common/style';
import useTimeline from '../../hooks/useTimeline';

interface DeleteTimelineModalProps {
  setOpenMenuId: (value: string | null) => void;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (value: boolean) => void;
  selectedTimelineToDelete: string | null;
}

const DeleteTimelineModal: React.FC<DeleteTimelineModalProps> = ({
  setOpenMenuId,
  selectedTimelineToDelete,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
}) => {
  const {
    timelines,
    deleteTimeline,
    selectedTimelineId,
    clearSelectedTimeline,
  } = useTimeline();

  const cancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async (timelineId: string) => {
    try {
      await deleteTimeline(timelineId);
      clearSelectedTimeline();
      setOpenMenuId(null);
    } catch (err) {
      console.error('Error deleting Timeline:', err);
    }
  };
  const confirmDelete = () => {
    if (selectedTimelineToDelete) {
      handleDelete(selectedTimelineToDelete);
      setIsDeleteModalOpen(false);
    }
  };

  const selectedTimelineCategory = timelines.find(
    (timeline) => timeline._id === selectedTimelineId
  )?.category;

  return (
    <>
      {isDeleteModalOpen && (
        <Modal isOpen={isDeleteModalOpen} onClose={cancelDelete}>
          <Box p={3}>
            <Text as="h3" m={3}>
              Delete timeline?
            </Text>
            <Hr />
            <Text as="p" m={3}>
              This will delete{' '}
              <Text as="span" bold>
                {selectedTimelineCategory} {''}
              </Text>
              timeline.
            </Text>

            <Flex flexDirection="row" m={3}>
              <Box mb={3} mr={4}>
                <Button styleType="danger" onClick={confirmDelete} bold>
                  Delete
                </Button>
              </Box>
              <Box>
                <Button
                  styleType="secondaryOutline"
                  onClick={cancelDelete}
                  bold
                >
                  Cancel
                </Button>
              </Box>
            </Flex>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default DeleteTimelineModal;

import { useContext } from 'react';

import { FeedbackContext, FeedbackContextType } from '../../Context/Feedback';

const useFeedback = (): FeedbackContextType => {
  const context = useContext(FeedbackContext);

  if (context === null) {
    throw new Error('useFeedback must be used within an FeedbackProvider');
  }

  return {
    ...context,
  };
};

export default useFeedback;

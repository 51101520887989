import { RotatableIconContainer, RotatableIconStyled } from './style';
import theme from '../../theme';

const RotatableIcon = ({
  _id,
  onToggle,
  isOpen,
}: {
  _id: string;
  onToggle: (id: string | null) => void;
  isOpen: boolean;
}) => {
  const toggleMenu = (event: React.MouseEvent<SVGElement>) => {
    event.stopPropagation();
    onToggle(isOpen ? null : _id);
  };

  return (
    <RotatableIconContainer isOpen={isOpen}>
      <RotatableIconStyled onClick={toggleMenu} color={theme.colors.medium} />
    </RotatableIconContainer>
  );
};

export default RotatableIcon;

import React, { useState, useEffect, useRef } from 'react';

interface ImageZoomProps {
  children: React.ReactNode;
  zoom: number;
  setZoom: (zoom: number | ((prevZoom: number) => number)) => void;
  isPinching: boolean;
  setIsPinching: (isPinching: boolean) => void;
}

const ImageZoom: React.FC<ImageZoomProps> = ({
  children,
  zoom,
  setZoom,
  isPinching,
  setIsPinching,
}) => {
  const [touchStartDistance, setTouchStartDistance] = useState(0);
  const [initialZoom, setInitialZoom] = useState(1);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleTouchStart = (e: TouchEvent) => {
    if (e.touches.length === 2) {
      const dx = e.touches[0].clientX - e.touches[1].clientX;
      const dy = e.touches[0].clientY - e.touches[1].clientY;
      const distance = Math.sqrt(dx * dx + dy * dy);
      setTouchStartDistance(distance);
      setInitialZoom(zoom);
      setIsPinching(true);
    }
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (e.touches.length === 2 && isPinching && touchStartDistance > 0) {
      e.preventDefault();
      const dx = e.touches[0].clientX - e.touches[1].clientX;
      const dy = e.touches[0].clientY - e.touches[1].clientY;
      const currentDistance = Math.sqrt(dx * dx + dy * dy);

      const scaleFactor = currentDistance / touchStartDistance;
      const newZoom = Math.min(Math.max(1, initialZoom * scaleFactor), 3);

      setZoom(newZoom);
    }
  };

  const handleTouchEnd = () => {
    setIsPinching(false);
  };

  const handleWheel = (e: WheelEvent) => {
    e.preventDefault();
    setZoom((prevZoom: number) =>
      Math.min(3, Math.max(1, prevZoom - e.deltaY * 0.01))
    );
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener('touchstart', handleTouchStart, {
        passive: false,
      });
      container.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
      container.addEventListener('wheel', handleWheel, { passive: false });
      container.addEventListener('touchend', handleTouchEnd);

      return () => {
        container.removeEventListener('touchstart', handleTouchStart);
        container.removeEventListener('touchmove', handleTouchMove);
        container.removeEventListener('wheel', handleWheel);
        container.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, [zoom, isPinching, touchStartDistance]);

  return (
    <div ref={containerRef} style={{ touchAction: 'none' }}>
      {children}
    </div>
  );
};

export default ImageZoom;

import { RxCross2 as Icon } from 'react-icons/rx';
import { MdModeEdit } from 'react-icons/md';
import { Flex } from '../Grid';
import { BaseModal, Content, IconWrapper, Overlay, Wrap } from './style';
import { ModalProps } from './types';

const MODAL_STYLE = {
  overlay: {
    zIndex: 9999,
  },
};

const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  fullPage = false,
  closeIcon = true,
  iconColor = 'light',
  background = null,
  noBackground = false,
  panelBackground = false,
  isEditIcon = false,
  hideEditIcon = true,
  handleEditClick,
  onClose,
  ...modalProps
}: ModalProps) => {
  return (
    <BaseModal
      isOpen={isOpen}
      style={MODAL_STYLE}
      onRequestClose={onClose}
      {...modalProps}
    >
      <Overlay onClick={onClose} />
      <Wrap
        fullPage={fullPage}
        background={background}
        noBackground={noBackground}
      >
        <Flex
          justifyContent="flex-end"
          padding="10px 0px"
          backgroundColor={panelBackground ? 'ultraLight' : null}
        >
          {isEditIcon && hideEditIcon && (
            <IconWrapper aria-label="edit-button" onClick={handleEditClick}>
              <MdModeEdit width="18px" height="18px" color={iconColor} />
            </IconWrapper>
          )}
          {closeIcon && (
            <IconWrapper aria-label="close-button" onClick={onClose}>
              <Icon width="18px" height="18px" color={iconColor} type="cross" />
            </IconWrapper>
          )}
        </Flex>
        <Content>{children}</Content>
      </Wrap>
    </BaseModal>
  );
};

export default Modal;

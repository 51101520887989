import axios, { AxiosResponse } from 'axios';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface ApiCallerProps<T = Record<string, unknown> | FormData> {
  endpoint: string;
  method?: HttpMethod;
  headers?: Record<string, string>;
  body?: T;
}

export class ApiError extends Error {
  public name = 'ApiError';

  constructor(public status: number, message: string) {
    super(message);
  }
}

// Updated to include a useBearer flag
async function apiCaller<T, B = Record<string, unknown>>({
  endpoint,
  method = 'GET',
  headers = {},
  body,
  includeToken = false,
}: ApiCallerProps<B> & {
  includeToken?: boolean;
}): Promise<T> {
  try {
    // Automatically add the Authorization header if includeToken is true
    if (includeToken) {
      const token = localStorage.getItem('token');
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
    }

    const config: Record<string, unknown> = {
      url: endpoint,
      method,
      headers: {
        ...(body instanceof FormData
          ? {}
          : { 'Content-Type': 'application/json' }),
        ...headers,
      },
      data: body,
      withCredentials: true,
    };

    const response: AxiosResponse<T> = await axios(config);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new ApiError(
        error.response.status,
        error.response.data.error || 'Unknown error'
      );
    }
    throw error;
  }
}

export default apiCaller;

import { useEffect, useState } from 'react';
import useNotifications from '../hooks/useNotifications';
import { formatDate } from '../Utils/helper';
import { Box, Flex, Chip, Text, Button, Modal, Back } from '../../storyBook';
import { useIsMedia } from '../../storyBook';
import { CenterText } from '../_common/style';
import { NotificationContainer, NotificationCard } from './style';
import Loader from '../_common/Loader';
import theme from '../../theme';
import { Hr } from '../_common/style';

const Notifications = () => {
  const { isMobile } = useIsMedia();
  const [isClealAllModalOpen, setIsClealAllModalOpen] = useState(false);
  const {
    loading,
    notifications,
    markAllNotificationsAsRead,
    deleteNotification,
    clearNotifications,
  } = useNotifications();

  if (loading) {
    return <Loader isLoading={true} />;
  }

  // handle clear all notifications
  const handleClearAll = () => {
    clearNotifications();
    setIsClealAllModalOpen(false);
  };

  // open modal to confirm clear all notifications
  const openClearAllModal = () => {
    setIsClealAllModalOpen(true);
  };

  // mark all notifications as read
  useEffect(() => {
    if (notifications.some((notification) => !notification.read)) {
      markAllNotificationsAsRead();
    }
  }, [notifications, markAllNotificationsAsRead]);

  const isSameDate = (date1: Date, date2: Date) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const handleBack = () => {
    history.back();
  };

  return (
    <>
      <Box mb={2}>
        <Back arrow onClick={handleBack} />
      </Box>
      <Flex justifyContent="space-between">
        <Text as="h2" mb={[3, 3, 3, 4]} color={theme.colors.mediumDark}>
          Notifications
        </Text>
        {notifications.length > 0 && (
          <Box width="80px">
            <Button
              styleType="danger"
              bold
              size="small"
              onClick={openClearAllModal}
            >
              Clear all
            </Button>
          </Box>
        )}
      </Flex>
      <NotificationContainer>
        {notifications.length === 0 ? (
          <NotificationCard>
            <CenterText>No notifications</CenterText>
          </NotificationCard>
        ) : (
          notifications.map((notification, index) => {
            const notificationDate = new Date(notification.data?.reminderDate);
            const today = new Date();
            const headerText = isSameDate(notificationDate, today)
              ? 'New Notification'
              : 'Previous Notification';
            return (
              <NotificationCard
                key={index}
                isPrevious={!isSameDate(notificationDate, today)}
              >
                <CenterText bold>{headerText}</CenterText>
                <Text color={theme.colors.mediumDark}>
                  {notification.message}
                </Text>
                <Box mb={2}>
                  <Flex
                    justifyContent="space-between"
                    flexDirection={isMobile ? 'column' : 'row'}
                    textAlign={isMobile ? 'center' : 'left'}
                  >
                    <Box mb={isMobile ? 3 : 0}>
                      <Chip
                        label={notification.data?.status}
                        status={notification.data?.status}
                      />
                      <Text as="h4" bold color={theme.colors.mediumDark}>
                        {notification.data?.content}
                      </Text>
                    </Box>

                    <Box>
                      <Text as="h4" bold color={theme.colors.mediumDark}>
                        {formatDate(new Date(notification.data?.reminderDate))}
                      </Text>
                      <Text as="h4" bold color={theme.colors.mediumDark}>
                        {notification.data?.reminderTime}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Flex justifyContent="flex-end">
                  <Box />
                  <Box width={isMobile ? '100%' : '90px'}>
                    <Button
                      styleType="danger"
                      size="small"
                      bold
                      onClick={() => deleteNotification(notification.data?._id)}
                    >
                      Delete
                    </Button>
                  </Box>
                </Flex>
              </NotificationCard>
            );
          })
        )}
      </NotificationContainer>
      {isClealAllModalOpen && (
        <Modal
          isOpen={isClealAllModalOpen}
          onClose={() => setIsClealAllModalOpen(false)}
        >
          <Box p={3}>
            <Text as="h3" m={3}>
              Clear all notifications?
            </Text>
            <Hr />
            <Text as="h4" m={3}>
              Are you sure you want to clear all notifications? Once cleared,
              viewed notifications cannot be back.
            </Text>
            <Flex flexDirection={isMobile ? 'column' : 'row'} m={3}>
              <Box mb={3} mr={[0, 4, 4]}>
                <Button styleType="danger" onClick={handleClearAll} bold>
                  Clear All
                </Button>
              </Box>
              <Box>
                <Button
                  styleType="secondaryOutline"
                  onClick={() => setIsClealAllModalOpen(false)}
                  bold
                >
                  Cancel
                </Button>
              </Box>
            </Flex>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default Notifications;

import styled from 'styled-components';
import {
  compose,
  space,
  layout,
  typography,
  color,
  flexbox,
  SpaceProps,
  LayoutProps,
  TypographyProps,
  ColorProps,
  FlexboxProps,
} from 'styled-system';

export type BoxProps =
  | SpaceProps
  | LayoutProps
  | TypographyProps
  | ColorProps
  | FlexboxProps;

export const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;

  ${compose(space, layout, typography, color, flexbox)}
`;

export const Flex = styled(Box)`
  display: flex;
`;

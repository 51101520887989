/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointNamesMap } from '../types';

const createMediaQueries = (breakpoints: BreakpointNamesMap): any => {
  const mediaQueries: any = {};

  Object.keys(breakpoints).forEach((key: string) => {
    mediaQueries[key] = `@media screen and (min-width: ${
      breakpoints[key as keyof BreakpointNamesMap]
    })`;
  });

  return mediaQueries;
};

export default createMediaQueries;

import React, { useId } from 'react';
import { Container, ColorInput } from './style';
import Text from '../Text';

export interface ColorPickerProps {
  color: string | null;
  onChange: (color: string) => void;
  label?: string;
  title?: string;
  ariaLabel: string;
}

const ColorPicker = ({
  color,
  onChange,
  label,
  title = 'Favorite Color',
  ariaLabel,
}: ColorPickerProps) => {
  const inputId = useId();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Container>
      {label && (
        <Text as="small" htmlFor={inputId}>
          {label}
        </Text>
      )}
      <ColorInput
        aria-label={ariaLabel}
        id={inputId}
        onChange={handleInputChange}
        title={title}
        type="color"
        value={color || '#000000'}
      />
    </Container>
  );
};

export default ColorPicker;

const getApiBaseUrl = () => {
  // Set your production and development base URLs
  const productionBaseUrl = process.env.REACT_APP_PRODUCTION_HOST;
  const developmentBaseUrl = process.env.REACT_APP_LOCAL_HOST;

  // Determine the environment and return the corresponding base URL
  return process.env.NODE_ENV === 'production'
    ? productionBaseUrl
    : developmentBaseUrl;
};

export default getApiBaseUrl;

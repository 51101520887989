import React from 'react';
import {
  Nav,
  MobileItem,
  MobileItemLink,
  IconContainer,
  IconText,
} from './styles';

type MenuItem = {
  title: string;
  icon: React.ReactNode;
  route: string;
};

type MobileNavigationProps = {
  menu: MenuItem[];
};

const MobileNavigation: React.FC<MobileNavigationProps> = ({ menu }) => {
  return (
    <Nav>
      {menu.map((item: MenuItem, index: number) => (
        <MobileItemLink key={index} to={item.route} activeClassName="active">
          <MobileItem>
            <IconContainer>{item.icon}</IconContainer>
            <IconText>{item.title}</IconText>
          </MobileItem>
        </MobileItemLink>
      ))}
    </Nav>
  );
};

export default MobileNavigation;

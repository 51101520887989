import { Timeline } from '../../types';
import dayjs, { Dayjs } from 'dayjs';
import { formatDate } from '../helper';
// Validation functions
export const identifierValidation = (value: string) => {
  const phoneRegex = /^\d{11}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (phoneRegex.test(value) || emailRegex.test(value)) return null;
  return 'Enter a valid email or phone number.';
};

export const firstNameValidation = (firstNameValue: string) => {
  if (!firstNameValue) return 'First name is required.';
  if (firstNameValue.length < 2)
    return 'First name must be at least 2 characters long.';
  return null;
};

export const lastNameValidation = (lastNameValue: string) => {
  if (!lastNameValue) return 'Last name is required.';
  if (lastNameValue.length < 2)
    return 'Last name must be at least 2 characters long.';
  return null;
};

export const emailValidation = (
  emailValue: string,
  emailExists: boolean = false
) => {
  if (!emailValue) return 'Email is required.';

  // Simple regex pattern to validate an email
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!emailPattern.test(emailValue))
    return 'Please enter a valid email address.';
  if (emailExists) return 'Email already exists';
  return null;
};

export const phoneNumberValidation = (
  phoneNumberValue: string,
  phoneExists: boolean = false
) => {
  if (!phoneNumberValue) return 'Phone number is required.';
  if (phoneNumberValue.length !== 11)
    return 'Phone number must be exactly 11 digits.';
  if (!/^\d+$/.test(phoneNumberValue))
    return 'Phone number must contain only digits.';
  if (phoneExists) return 'Phone number already exists';
  return null;
};

export const passwordValidation = (passwordValue: string) => {
  if (!passwordValue) return 'Password is required.';
  if (passwordValue.length < 6)
    return 'Password must be at least 6 characters long.';
  return null;
};

export const confirmPasswordValidation = (
  password: string,
  confirmPassword: string
) => {
  if (!confirmPassword) return 'Confirm password is required.';
  if (password !== confirmPassword) return 'Passwords do not match.';
  return null;
};

// Validation functions for AddNewTimeline
const validateContent = (content: string) => content.trim() !== '';

const validateCategory = (category: string) => category !== 'Select...';

const validateDate = (date: Dayjs | null) => {
  // Validate that the date is not null and is a valid Dayjs object
  return date !== null && dayjs(date).isValid();
};

const validateFutureDateAndTime = (date: Dayjs | null) => {
  if (date) {
    const startOfToday = dayjs().startOf('day');
    const dateTime = dayjs(date.format('YYYY-MM-DD'));
    // Check if the date is not before today
    return !dateTime.isBefore(startOfToday);
  }
  return false;
};

const isDuplicateTimeline = (
  date: Dayjs | null,
  time: string,
  timelines: Timeline[],
  currentNoteId?: string
) => {
  if (!date || !time) {
    return { isDuplicate: false, category: null };
  }

  const dateTime = dayjs(`${date.format('YYYY-MM-DD')} ${time}`);
  const duplicate = timelines.find((timeline) => {
    // Check if reminderDate is defined before formatting
    if (timeline.reminderDate) {
      const formattedReminderDate = formatDate(new Date(timeline.reminderDate));
      const timelineDateTime = dayjs(
        `${formattedReminderDate} ${timeline.reminderTime}`
      );
      return (
        dateTime.isSame(timelineDateTime) &&
        (!currentNoteId || timeline._id !== currentNoteId)
      );
    }
    return false;
  });

  return {
    isDuplicate: !!duplicate,
    category: duplicate ? duplicate.category : null,
  };
};

const validateTime = (time: string) =>
  !!time && /^([0-1]?[0-9]|2[0-3]):[0-5][0-9] (AM|PM)$/.test(time);

export const validateForm = (
  content: string,
  category: string,
  date: Dayjs | null,
  time: string,
  timelines: Timeline[],
  currentNoteId?: string
) => {
  const errors: Record<string, string> = {};
  const { isDuplicate, category: duplicateCategory } = isDuplicateTimeline(
    date || null,
    time,
    timelines,
    currentNoteId
  );

  if (!validateContent(content))
    errors.content = 'Timeline content is required.';
  if (!validateCategory(category))
    errors.category = 'Please select a category.';
  if (!validateDate(date)) errors.date = 'Please enter a valid date.';
  if (!validateTime(time)) errors.time = 'Please enter a valid time (hh:mm A).';
  if (!validateDate(date) || !validateFutureDateAndTime(date)) {
    errors.dateTime = 'Please select a future date.';
  }
  if (isDuplicate) {
    errors.duplicate = `A timeline entry for '${duplicateCategory}' already exists at the selected date and time. Please choose a different date or time, or modify the existing timeline.`;
  }

  return errors;
};

// validation functions for AddNewSnapNote
const validateTitle = (label: string) => label.trim() !== '';

export const validateNoteForm = (label: string) => {
  const errors: Record<string, string> = {};

  if (!validateTitle(label)) errors.label = 'Label is required.';

  return errors;
};
export const removeValidationError = (
  errors: Record<string, string>,
  key: string
) => {
  const newErrors = { ...errors };
  delete newErrors[key];
  return newErrors;
};

// Function that checks for an error and removes it if necessary
export const validateField = (
  errors: Record<string, string>,
  key: string,
  validator: () => boolean
) => {
  if (validator()) {
    return removeValidationError(errors, key);
  }
  return errors;
};

//password site validation
export const siteValidation = (siteValue: string) => {
  if (!siteValue) return 'Site is required.';

  // Simple regex to validate a basic URL format
  const sitePattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})\/?$/;
  if (!sitePattern.test(siteValue))
    return 'Please enter a valid site URL (example.com or http://example.com).';

  return null;
};

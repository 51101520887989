// usePushNotifications.js
import { useEffect } from 'react';
import useNotifications from '../useNotifications';
import { registerPushSubscription } from '../../Utils/pushNotificationUtils';
import { Notification } from '../../Context/NotificationsContext';
import useAuth from '../useAuth';

const usePushNotifications = (): void => {
  const { addNotification } = useNotifications();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      console.log('Registering service worker...');
      navigator.serviceWorker.ready.then((registration) => {
        registerPushSubscription(registration, isAuthenticated);
      });
    }
  }, [isAuthenticated]);

  const addNewNotification = (notification: Notification): void => {
    // Update the notifications state
    addNotification(notification);
  };

  useEffect(() => {
    const receiveMessage = (event: MessageEvent): void => {
      if (event.data && event.data.message) {
        const newNotification: Notification = event.data;

        addNewNotification(newNotification);
      }
    };

    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.addEventListener('message', receiveMessage);
    }

    return () => {
      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        navigator.serviceWorker.removeEventListener('message', receiveMessage);
      }
    };
  }, [addNotification]);
};

export default usePushNotifications;

import { Card, Flex, Box, Text, Button } from '../../../../storyBook';
import { formatDate } from '../../../Utils/helper';
import theme from '../../../../theme';
import { Hr, CenterText } from '../../../_common/style';
import { Notification } from '../../../Context/NotificationsContext';
import {
  FullPageContainer,
  AnimatedBackground,
  ContentContainer,
} from './style';

type Props = {
  onDeactivate: () => void;
  notification: Notification;
};

// Component
const RingAlarmDisplay: React.FC<Props & { notification: Notification }> = ({
  onDeactivate,
  notification,
}) => {
  return (
    <FullPageContainer>
      <AnimatedBackground />
      <ContentContainer>
        <Box>
          <CenterText as="h3" bold mb={3}>
            {notification.message}
          </CenterText>
          <Hr />

          <Flex justifyContent="space-around" alignItems="center" mb={3} mt={3}>
            <Text bold color={theme.colors.mediumDark}>
              {formatDate(new Date(notification.data.reminderDate))}
            </Text>
            <Text bold color={theme.colors.mediumDark}>
              {notification.data.reminderTime}
            </Text>
          </Flex>

          <Hr />

          <Card background={theme.colors.ultraLight}>
            <Text mb={4} mt={3}>
              {notification.data.content}
            </Text>
          </Card>
        </Box>
        <Button bold onClick={onDeactivate} styleType="danger" size="medium">
          Deactivate Ring
        </Button>
      </ContentContainer>
    </FullPageContainer>
  );
};

export default RingAlarmDisplay;

import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { Text, Box } from '../../storyBook';
import theme from '../../theme';
import { rem } from 'polished';

export const Separator = styled.div`
  width: 1px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.medium};
  margin: 3px ${({ theme }) => theme.spacing.xs};
`;

export const Hr = styled(Box)`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.light};
`;

export const NavLink = styled(RouterLink)`
  text-decoration: none;
`;

export const CenterText = styled(Text)`
  text-align: center;
`;

export const LinkText = styled(Text).attrs({
  as: 'p',
})`
  display: inline-block;
  color: ${({ theme }) => theme.colors.supportingBlueLight};
  text-decoration: underline;
  cursor: pointer;
  text-align: ${({ center }) => (center ? 'center' : null)};
`;

export const StyledLink = styled(({ to, children, ...rest }) =>
  to ? (
    <a href={to} {...rest}>
      {children}
    </a>
  ) : (
    <div>{children}</div>
  )
)`
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Img = styled.img`
  width: 100%;
`;

export const Form = styled.form``;

export const ErrorMessage = styled.p`
  color: ${theme.colors.primaryRed};
  font-size: ${rem(12)};
  margin-top: ${rem(4)};
`;

export const StyleButton = styled.button`
  background: ${({ theme }) => theme.colors.supportingBlue};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 15px;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
  &:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  }
  ${({ theme }) => theme.media.desktop} {
    padding: 15px 20px;
    font-size: 16px;
  }
`;

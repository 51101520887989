import { useContext } from 'react';
import { TimelineContext } from '../../Context/TimelineContext';
import { TimelineContextType } from '../../Context/TimelineContext/types';

const useTimeline = (): TimelineContextType => {
  const context = useContext(TimelineContext);

  if (context === null) {
    throw new Error('useTimeline must be used within an TimelineProvider');
  }

  return {
    ...context,
  };
};

export default useTimeline;

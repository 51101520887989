import styled, { keyframes } from 'styled-components';

// Styled components
export const FullPageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const movingAnimation = keyframes`
  0% { background-position: 0 0; }
  100% { background-position: 50px 50px; }
`;

export const AnimatedBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.05) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.05) 50%,
    rgba(255, 255, 255, 0.05) 75%,
    transparent 75%,
    transparent
  );
  background-size: 50px 50px;
  animation: ${movingAnimation} 2s linear infinite;
`;

export const ContentContainer = styled.div`
  position: relative;
  z-index: 10;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  max-width: 600px;
`;

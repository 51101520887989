// useUpdateUser.ts
import { useState } from 'react';
import apiCaller from '../../Utils/apiCaller';
import { handleError } from '../../Utils/helper';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';

type UpdateUserProps = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  password?: string;
};

type UpdateUserResponse = {
  status: string;
};

const useUpdateUser = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<UpdateUserResponse | null>(null);
  const apiBaseUrl = getApiBaseUrl();

  const updateUser = async (details: UpdateUserProps) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<UpdateUserResponse>({
        endpoint: `${apiBaseUrl}/auth/update-details`,
        method: 'PUT',
        body: details,
      });
      setData(response);
    } catch (err) {
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateUser,
    loading,
    error,
    data,
  };
};

export default useUpdateUser;

import { useContext } from 'react';
import {
  NotificationContext,
  NotificationContextType,
} from '../../Context/NotificationsContext';

const useNotifications = (): NotificationContextType => {
  const context = useContext(NotificationContext);

  if (context === null) {
    throw new Error(
      'useNotifications must be used within an NotificationProvider'
    );
  }

  return {
    ...context,
  };
};

export default useNotifications;

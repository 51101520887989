import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import HeaderSignup from '../../Header/SignupHeader';
import Loader from '../../_common/Loader';
import {
  Modal,
  Box,
  Text,
  TextInput,
  Card,
  Button,
  Flex,
  useIsMedia,
} from '../../../storyBook';
import {
  passwordValidation,
  confirmPasswordValidation,
} from '../../Utils/validation';
import { Hr } from '../../_common/style';
import theme from '../../../theme';
import useResetPassword from '../../hooks/useResetPassword';
import { notify } from '../../../storyBook/TostNotification';
import { base64UrlDecode } from '../../Utils/base64Decode';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const history = useHistory();
  const { isMobile } = useIsMedia();
  const { resetPassword, loading } = useResetPassword();

  const handleBackClick = () => {
    history.push('/login');
  };

  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  let userId: string | undefined;

  if (token) {
    const parts = token.split('.');
    if (parts.length === 3) {
      const decodedPayload = JSON.parse(base64UrlDecode(parts[1]));
      userId = decodedPayload.userId;
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (userId) {
        const responseData = await resetPassword({
          userId: userId,
          newPassword: password,
        });

        // Assuming the function returns data or you can use the 'data' from the hook
        if (responseData?.status) {
          notify({ message: responseData.status, type: 'success' });
        } else {
          notify({ message: 'Unexpected response received.', type: 'error' });
        }
      }
    } catch (err: unknown) {
      const errorMsg = typeof err === 'string' ? err : 'An error occurred';
      notify({ message: errorMsg, type: 'error' });
    }

    setIsModalOpen(false);
    handleBackClick();
  };

  return (
    <>
      <HeaderSignup onBackClick={handleBackClick} />
      {loading && <Loader isLoading={loading} />}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          handleBackClick();
        }}
        fullPage={isMobile}
      >
        <Box m={3}>
          <Box mb={4}>
            {isMobile && (
              <HeaderSignup onBackClick={() => setIsModalOpen(false)} />
            )}
          </Box>
          <Card>
            <Box>
              <Text as="h3" mb={2} color={theme.colors.mediumDark}>
                Reset your password
              </Text>
              <Hr mb={3} />
              <Box mb={4}>
                <Text as="span" mb={2}>
                  Please provide a new password to complete your password reset.
                </Text>
              </Box>
            </Box>
            <Box mb={4}>
              <TextInput
                autoFocus
                background={theme.colors.ultraLight}
                placeholder="New password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                validation={passwordValidation}
                onValidation={setIsPasswordValid}
              />
              <TextInput
                background={theme.colors.ultraLight}
                placeholder="Confirm password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                validation={(value) =>
                  confirmPasswordValidation(password, value)
                }
                onValidation={setIsPasswordValid}
              />
            </Box>
            <Hr mb={3} />
            <Flex flexDirection="row" textAlign="end">
              <Box mr={3}>
                <Button
                  styleType="primary"
                  size="small"
                  type="submit"
                  bold
                  onClick={handleSubmit}
                  disabled={!isPasswordValid}
                >
                  Continue
                </Button>
              </Box>
              <Box>
                <Button
                  styleType="secondary"
                  size="small"
                  type="button"
                  onClick={handleBackClick}
                >
                  Cancel
                </Button>
              </Box>
            </Flex>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default ResetPassword;

import styled from 'styled-components';
import { rem } from 'polished';

import { Props } from './types';

export const StyledLabel = styled.label`
  margin-bottom: 8px;
  display: flex;
  font-weight: 600;
  font-size: 14px;
`;

export const Asterisk = styled.div`
  color: ${({ theme }): string => theme.colors.primaryRed};
  font-size: ${rem(18)};
  font-weight: 400;
  margin-left: 1px;
`;

export const Container = styled.textarea<Props>`
  width: 100%;
  outline: none;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.offBlack};
  font-weight: 600;
  font-family: goodtimes, sans-serif;
  padding: ${({ theme }) => theme.spacing.xs};
  border: ${({ theme, isFocused }) =>
    isFocused
      ? `2px solid ${theme.colors.supportingBlueLight}`
      : `1px solid ${theme.colors.borderColor}`};
  resize: ${({ resize }) => !resize && 'none'};
  border-radius: ${rem(4)};
  background-color: ${({ theme }) => theme.colors.ultraLight};

  &::placeholder {
    color: ${({ theme }) => theme.colors.medium};
  }
`;

import { rem } from 'polished';
import { SpacingsMap } from './types';

const space: number[] = [8, 16, 24, 32, 40, 72, 160];

const spacing: SpacingsMap = {
  xs: rem(space[0]), // 0.5rem
  sm: rem(space[1]), // 1rem
  rg: rem(space[2]), // 1.5rem
  md: rem(space[3]), // 2rem
  lg: rem(space[4]), // 2.5rem
  xl: rem(space[5]), // 4.5rem
  xxl: rem(space[6]), // 10rem
};

export default spacing;

import { GoStarFill } from 'react-icons/go';
import { Flex } from '../../storyBook';
import theme from '../../theme';

const StarRating = ({
  rating,
  onRatingChange,
}: {
  rating: number;
  onRatingChange: (index: number) => void;
}) => {
  const handleRating = (index: number) => {
    onRatingChange(index + 1);
  };

  return (
    <Flex>
      {Array.from({ length: 5 }, (_, index) => (
        <GoStarFill
          key={index}
          onClick={() => handleRating(index)}
          style={{
            cursor: 'pointer',
            color: index < rating ? theme.colors.pending : theme.colors.medium,
            fontSize: '25px',
          }}
        />
      ))}
    </Flex>
  );
};

export default StarRating;

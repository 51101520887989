import React from 'react';
import { ChipContainer } from './style';
import { StatusChipProps } from './types';

const Chip: React.FC<StatusChipProps> = ({
  status = 'Pending',
  label = 'Pending',
  active = false,
}) => {
  return (
    <ChipContainer status={status} active={active}>
      {label}
    </ChipContainer>
  );
};

export default Chip;

import React, { ReactNode, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

interface AutoRefreshingRouterProps {
  children: ReactNode;
}

export const AutoRefreshingRouter: React.FC<AutoRefreshingRouterProps> = ({
  children,
}) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => setShouldRefresh(true), 1000 * 60 * 60);
    return () => clearTimeout(id);
  }, []);

  return <BrowserRouter forceRefresh={shouldRefresh}>{children}</BrowserRouter>;
};

import { useState } from 'react';
import { Box, Text, TextInput, Button, Back } from '../../../../storyBook';
import theme from '../../../../theme';
import { useIsMedia } from '../../../../storyBook';
import {
  firstNameValidation,
  lastNameValidation,
  emailValidation,
  phoneNumberValidation,
} from '../../../Utils/validation';
import useUpdateUser from '../../../hooks/useUpdateUser';
import { notify } from '../../../../storyBook/TostNotification';
import Loader from '../../../_common/Loader';
import { ImSpinner8 } from 'react-icons/im';

const Details = () => {
  const { isMobile } = useIsMedia();
  const [disableButton, setdisableButton] = useState(true);
  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');

  const firstNameFallback = userDetails?.firstName;
  const lastNameFallback = userDetails?.lastName;
  const emailFallback = userDetails?.email;
  const phoneNumberFallback = userDetails?.phoneNumber;

  const { updateUser, loading, error, data } = useUpdateUser();

  const getPhoneNumberValue = (phoneNumber: string | null | undefined) => {
    // Check if phoneNumber is either null, the string "null", undefined, or all zeros
    if (!phoneNumber || phoneNumber === 'null' || /^0*$/.test(phoneNumber)) {
      return '';
    }
    return phoneNumber;
  };

  const [value, setValue] = useState({
    _id: userDetails._id,
    firstName: firstNameFallback,
    lastName: lastNameFallback,
    email: emailFallback,
    phoneNumber: getPhoneNumberValue(phoneNumberFallback),
  });

  const { firstName, lastName, email, phoneNumber } = value;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputChange = (key: string) => (e: any) => {
    setValue({
      ...value,
      [key]: e.target.value,
    });
    setdisableButton(false);
  };

  const handleBack = () => {
    history.back();
  };

  const handleSubmit = async () => {
    if (firstName && lastName && email && phoneNumber) {
      await updateUser({
        firstName,
        lastName,
        email,
        phoneNumber,
      });
      localStorage.setItem(
        'userDetails',
        JSON.stringify({ ...userDetails, firstName, lastName, phoneNumber })
      );
      // Check for a status in the backend data
      if (data?.status) {
        notify({ message: data.status, type: 'success' });
      } else if (error) {
        notify({ message: error, type: 'error' });
      }
    }
    setdisableButton(true);
  };

  return (
    <>
      {loading && <Loader isLoading={loading} />}
      <Box mb={3}>
        <Back arrow onClick={handleBack} />
      </Box>
      <Box>
        <Text as="h2" mb={4}>
          Personal information
        </Text>
        <Box mb={4} width={!isMobile ? '380px' : '100%'}>
          <Box mb={4}>
            <TextInput
              background={theme.colors.ultraLight}
              placeholder="First name"
              value={firstName}
              onChange={handleInputChange('firstName')}
              validation={firstNameValidation}
              onValidation={() => {}}
            />
          </Box>
          <Box mb={4}>
            <TextInput
              background={theme.colors.ultraLight}
              placeholder="Last name"
              value={lastName}
              onChange={handleInputChange('lastName')}
              validation={lastNameValidation}
              onValidation={() => {}}
            />
          </Box>
          <Box mb={4}>
            <TextInput
              background={theme.colors.ultraLight}
              placeholder="Phone number"
              value={phoneNumber}
              onChange={handleInputChange('phoneNumber')}
              validation={phoneNumberValidation}
              onValidation={() => {}}
            />
          </Box>
          <Box mb={4}>
            <TextInput
              background={theme.colors.ultraLight}
              placeholder="Email address"
              value={email}
              onChange={handleInputChange('email')}
              validation={emailValidation}
              onValidation={() => {}}
              disabled
            />
          </Box>

          <Button
            styleType="primary"
            size="large"
            type="submit"
            bold
            onClick={handleSubmit}
            disabled={
              !firstName ||
              !lastName ||
              !email ||
              !phoneNumber ||
              loading ||
              disableButton
            }
          >
            {loading ? (
              <ImSpinner8 size={20} style={{ verticalAlign: 'middle' }} />
            ) : (
              'Save'
            )}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Details;

import React, { useState } from 'react';
import {
  Modal,
  Box,
  Text,
  TextInput,
  Button,
  useIsMedia,
} from '../../../../storyBook';
import theme from '../../../../theme';
import useAuth from '../../../hooks/useAuth';
import { CenterText, Hr } from '../../../_common/style';
import { useHistory } from 'react-router-dom';
import SetMasterPassword from '../SetMasterPassword';
import ForgotPasswordModal from '../../../Login/ForgotPasswordModal';
import { StyleButton, StylePiLockKey } from './style';

interface ShowPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  passwordId: string | null;
}

const ShowPasswordModal = ({
  isOpen,
  onClose,
  passwordId,
}: ShowPasswordModalProps) => {
  const [enteredMasterPassword, setEnteredMasterPassword] = useState('');
  const [masterPasswordModal, setMasterPasswordModal] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const { isMobile } = useIsMedia();
  const history = useHistory();

  const fallbackUserDetails = JSON.parse(
    localStorage.getItem('userDetails') || '{}'
  );

  const userId = fallbackUserDetails?._id;
  const masterPasswordSet = fallbackUserDetails?.masterPasswordSet;

  const { forgotMasterPassword, masterPassword } = useAuth();

  const handleMasterPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnteredMasterPassword(e.target.value);
  };

  const handleValidate = async () => {
    try {
      await masterPassword(userId, enteredMasterPassword);
      history.push(`/passwords/${passwordId}`);
      onClose();
    } catch (error) {
      console.error('Error validating password:', error);
    }
  };

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Box p={3}>
          <Box mb={3}>
            <CenterText>
              <StylePiLockKey size={50} />
            </CenterText>
          </Box>
          <CenterText>
            <Text as="h2" mb={2} color={theme.colors.supportingBlueLight}>
              Livediary
            </Text>
            <Text mb={2}>Livediary is trying to show passwords for you.</Text>
            <Text mb={3}>Enter your master password to allow this.</Text>
          </CenterText>

          <Box mb={4}>
            <Hr />
          </Box>
          <TextInput
            placeholder="Master password"
            type="password"
            value={enteredMasterPassword}
            onChange={handleMasterPasswordChange}
          />

          <Box mt={3} mb={3}>
            <Button
              styleType="primary"
              size="medium"
              type="submit"
              bold
              onClick={handleValidate}
              disabled={!enteredMasterPassword || !masterPasswordSet}
            >
              Enter your master password
            </Button>
          </Box>
          {masterPasswordSet ? (
            <Box mb={3}>
              <StyleButton
                styleType="secondary"
                size="medium"
                bold
                onClick={() => {
                  setForgotPassword(true);
                  history.push('/password/forgot-master-password');
                }}
              >
                Reset Master Password
              </StyleButton>
            </Box>
          ) : (
            <Box mb={3}>
              <StyleButton
                styleType="secondary"
                size="medium"
                bold
                onClick={() => {
                  setMasterPasswordModal(true);
                  history.push('/password/set-master-password');
                }}
              >
                Set Master Password
              </StyleButton>
            </Box>
          )}
        </Box>
      </Modal>
      {masterPasswordModal && (
        <SetMasterPassword setMasterPasswordModal={setMasterPasswordModal} />
      )}
      {forgotPassword && (
        <ForgotPasswordModal
          isMobile={isMobile}
          onClose={() => setForgotPassword(false)}
          setForgotEmail={setForgotPassword}
          onSubmitEmail={forgotMasterPassword}
          masterPassword
        />
      )}
    </Box>
  );
};

export default ShowPasswordModal;

import { FC } from 'react';
import { CardProps } from './types';
import { CardContainer } from './style';

const Card: FC<CardProps> = ({
  children,
  background,
  height,
  cardRequired = true,
  padding,
  border,
  width,
  ...rest
}) => {
  return (
    <>
      {cardRequired ? (
        <CardContainer
          width={width}
          padding={padding}
          height={height}
          background={background}
          border={border}
          {...rest}
        >
          {children}
        </CardContainer>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default Card;

import React, { useState } from 'react';
import { Back, Box, Flex, Text, FloatingButton } from '../../../storyBook';
import theme from '../../../theme';
import { useHistory } from 'react-router-dom';
import AddPasswordModal from './AddPasswordModal';
import PasswordList from './PasswordList';

const PasswordSaver = () => {
  const history = useHistory();
  const [addModal, setAddModal] = useState(false);

  const handleBack = () => {
    history.goBack();
  };

  return (
    <>
      <Box mb={2}>
        <Back arrow onClick={handleBack} />
      </Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Text as="h2" mb={[3, 3, 3, 4]} color={theme.colors.mediumDark}>
          Passwords
        </Text>
        <Flex justifyContent="space-between" alignItems="center">
          <Box mt={-3}>
            <FloatingButton
              onClick={() => {
                setAddModal(true);
                history.push('/password');
              }}
            />
          </Box>
        </Flex>
      </Flex>
      <Text>
        Create, store, and manage passwords for easy website and app sign-ins.
      </Text>
      {addModal && <AddPasswordModal setAddModal={setAddModal} />}

      <PasswordList />
    </>
  );
};

export default PasswordSaver;

import styled from 'styled-components';
import { InnerProps, StyledComponentProps } from './types';

export const Main = styled.main<StyledComponentProps>`
  grid-template-columns: 1fr;
  margin-top: ${({ theme }) => theme.spacing.sm};
  ${({ theme }) => theme.media.tablet} {
    grid-template-columns: 250px 1fr;
    display: grid;
    gap: 40px; // Adjust gap size as per your requirement
    align-items: start;
    height: max-content; // Adjust height according to content
    background-color: ${({ theme }) => theme.colors.white};
    margin: ${({ theme }) => theme.spacing.xl};
    margin-top: ${({ theme }) => theme.spacing.lg};
  }
`;

export const LeftColumn = styled.div`
  ${({ theme }) => theme.media.tablet} {
    display: block;
    overflow-y: auto;
    padding: ${({ theme }) => theme.spacing.sm};
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.light};
    position: sticky;
    top: 100px;
  }
`;

export const RightColumn = styled.div<StyledComponentProps>`
  margin: 0 ${({ theme }) => theme.spacing.sm};
  padding-bottom: ${({ theme }) => theme.spacing.xxl};

  ${({ theme }) => theme.media.smallTablet} {
    overflow-y: auto;
    padding: ${({ theme }) => theme.spacing.sm};
    border-radius: 10px;
    position: relative;
    padding-bottom: 80px;

    border: 1px solid ${({ theme }) => theme.colors.light};
    ${({ withBackground, theme }) =>
      withBackground &&
      `
      background-color: ${theme.colors.light};
      padding: 30px;
    `}
  }
`;

export const Inner = styled.div<InnerProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding-bottom: ${({ theme, mobileBottomPadding }) =>
    mobileBottomPadding ? theme.spacing.sm : 0};
`;

import { DefaultsMap } from './types';

export const defaults: DefaultsMap = {
  fontFamily:
    '"Good Sans", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
  fontSize: '16px',
  lineHeight: 1.45,
  fontWeight: 400,
  gutterSpacing: '24px',
};

import styled from 'styled-components';
import { Box } from '../Grid';
import { CardProps } from './types';
import { space } from 'styled-system';
import theme from '../../theme';

export const CardContainer = styled(Box)<CardProps>`
  width: ${({ width }) => width || '100%'};
  background: ${({ background, theme }) => background || theme.colors.white};
  padding: ${({ padding }) => padding || '0rem 1rem'};
  box-shadow: ${({ background }) => background && '0px 4px 12px 0px #0000000a'};
  border-radius: 6px;
  height: ${({ height }) => height && `${height}px`};
  transition: all 0.3s;
  text-align: justify;

  border: ${({ theme, border, background }) => {
    if (!border) {
      return '';
    }

    if (!background) {
      return `1px solid ${theme.colors.light}`;
    }

    return `1px solid ${theme.colors.light}`;
  }};

  &:hover {
    border: ${({ border }) => {
      if (border) {
        return `1px solid ${theme.colors.light}`;
      }
      return '';
    }};
    transition: all 0.3s;
  }
  ${space};
`;
